<template>
  <div class="menu-fixed">
    <div id="error" class="error">
      <div class="gridbox">
        <div class="flexbox">
          <button @click.prevent="fecharTelaMensagens()">
            <img src="../assets/imagens/icon-close.png" alt="Botão de fechar" class="botao-fechar">
          </button>
        </div>
        <h2>Não foi possível salvar, pois:</h2>
        <ul>
          <li v-if="mensagensModal.servidor_cpf !== '' && mensagensModal.servidor_cpf !== undefined">
            <div v-if="typeof mensagensModal.servidor_cpf == 'object'">
              {{ mensagensModal.servidor_cpf[0] }}
            </div>
            <div v-else>
              {{ mensagensModal.servidor_cpf }}
            </div>
          </li>
          <li v-if="mensagensModal.servidor_nome !== '' && mensagensModal.servidor_nome !== undefined">
            <div v-if="typeof mensagensModal.servidor_nome == 'object'">
              {{ mensagensModal.servidor_nome[0] }}
            </div>
            <div v-else>
              {{ mensagensModal.servidor_nome }}
            </div>
          </li>
          <li v-if="mensagensModal.servidor_cargo !== '' && mensagensModal.servidor_cargo !== undefined">
            <div v-if="typeof mensagensModal.servidor_cargo == 'object'">
              {{ mensagensModal.servidor_cargo[0] }}
            </div>
            <div v-else>
              {{ mensagensModal.servidor_cargo }}
            </div>
          </li>
          <li v-if="mensagensModal.default !== '' && mensagensModal.default !== undefined">
            <div v-if="typeof mensagensModal.default == 'object'">
              {{ mensagensModal.default[0] }}
            </div>
            <div v-else>
              {{ mensagensModal.default }}
            </div>
          </li>
          <li v-if="mensagensModal !== '' && mensagensModal !== undefined && typeof mensagensModal == 'string'">
            <div>
              {{ mensagensModal }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MsgErroFormularios",
    props: {
      mensagensModal: {
        type: [Object, String],
        required: true
      },
      erroModal: {
        type: Boolean,
        required: true,
      }
    },
    methods: {
      fecharTelaMensagens() {
        this.$emit("fechar-erro");
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "60px") and (max-width: "425px") {
    .menu-fixed {
      width: 100%;
      .error {
        background-color: #f71216e7;
        border-radius: 7px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-bottom: 0.9rem;
        text-align: center;

        .gridbox {
          display: grid;
          gap: .2rem;

          .flexbox {
            display: flex;
            flex-direction: row-reverse;

            button {
              border: none;
              background-color: transparent;
              min-height: 1.5rem;
              max-height: 2rem;

              .botao-fechar {
                margin: -1rem .2rem 0 0;
                width: .8rem;
                height: .8rem;
              }

              .botao-fechar:hover {
                padding: .1rem;
              }
            }
          }

          h2 {
            display: block;
            margin-bottom: .5rem;
            font-size: .85rem;
            font-weight: bold;
            font-family: "Montserrat-Medium";
            cursor: default;
            color: #000;
          }

          ul {
            display: block;
            list-style-type: disc;
            margin: .5rem 0;

            li {
              font-size: .85rem;
              display: list-item;
              text-align: left;
              color: black;
              background-color: #f7121609;
              font-family: "Montserrat-Medium";
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: "426px") and (max-width: "1024px") {
    .menu-fixed {
      width: 100%;
      .error {
        background-color: #f71216e7;
        border-radius: 7px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-bottom: 0.9rem;
        text-align: center;

        .gridbox {
          display: grid;
          gap: .2rem;

          .flexbox {
            display: flex;
            flex-direction: row-reverse;

            button {
              border: none;
              background-color: transparent;
              min-height: 1.5rem;
              max-height: 2rem;

              .botao-fechar {
                margin: -1rem .2rem 0 0;
                width: .8rem;
                height: .8rem;
              }

              .botao-fechar:hover {
                padding: .1rem;
              }
            }
          }

          h2 {
            display: block;
            margin-bottom: .5rem;
            font-size: 1rem;
            font-weight: bold;
            font-family: "Montserrat-Medium";
            cursor: default;
            color: #000;
          }

          ul {
            display: block;
            list-style-type: disc;
            margin: .5rem 0;

            li {
              font-size: .9rem;
              display: list-item;
              text-align: left;
              color: black;
              background-color: #f7121609;
              font-family: "Montserrat-Medium";
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: "1025px") and (max-width: "2560px") {
    .menu-fixed {
      width: 100%;
      .error {
        background-color: #f71216e7;
        border-radius: 7px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-bottom: 0.9rem;
        text-align: center;

        .gridbox {
          display: grid;
          gap: .2rem;

          .flexbox {
            display: flex;
            flex-direction: row-reverse;

            button {
              border: none;
              background-color: transparent;
              min-height: 1.5rem;
              max-height: 2rem;

              .botao-fechar {
                margin: -.5rem .3rem 0 0;
                width: 1rem;
                height: 1rem;
              }

              .botao-fechar:hover {
                padding: .1rem;
              }
            }
          }

          h2 {
            display: block;
            margin-bottom: .5rem;
            font-size: 1.2rem;
            font-weight: bold;
            font-family: "Montserrat-Medium";
            cursor: default;
            color: #000;
          }

          ul {
            display: block;
            list-style-type: disc;
            margin: .5rem 0;

            li {
              font-size: 1.1rem;
              display: list-item;
              text-align: left;
              color: black;
              background-color: #f7121609;
              font-family: "Montserrat-Medium";
            }
          }
        }
      }
    }
  }
</style>