<template>
  <div id="servidores">
    <div class="itemTop">
      <label>{{ possuiAsterisco("Adicionar Servidores:") }}</label>
      <div class="bt-incluir">
        <button type="button" @click="adicionarServidores()">
          <span>+</span> Adicionar
        </button>
      </div>
    </div>
    <template v-for="(servidores, i) in local">
      <template>
        <div :key="servidores.id">
          <template>
            <div
              class="caixa-form-servidores item-servidores"
              v-if="servidores._destroy !== true"
            >
              <!-- Adicionar Servidor -->
              <div class="divServidores" v-if="flagServidores == false">
                <div class="botao-excluir">
                  <button type="button" @click="removerServidor(servidores, i)">
                    <img
                      src="../../assets/imagens/icon-delete-preto.svg"
                      alt="Remover"
                      title="Remover"
                    />
                  </button>
                </div>
                <div class="dados">
                  <div class="first">
                    <div class="cpf">
                      <label>{{ possuiAsterisco("CPF:") }}</label>
                      <the-mask
                        id="inputCpfServidor"
                        :mask="['###.###.###-##']"
                        placeholder="Digite o CPF do(a) servidor(a)"
                        v-model="servidores.cpf"
                      />
                    </div>
                    <div class="nome" v-if="servidores.nome !== null">
                      <label>{{ possuiAsterisco("Nome:") }}</label>
                      <input
                        type="text"
                        placeholder="Digite o nome do(a) servidor(a)"
                        v-model="servidores.nome"
                      />
                    </div>
                    <div class="cargo" v-if="servidores.cargo !== null">
                      <label>{{ possuiAsterisco("Cargo:") }}</label>
                      <!--<input type="text" placeholder="Digite o cargo do(a) servidor(a)" v-model="servidores.cargo" />-->
                      <v-select
                        class=".vselect-sem-borda .vselect-static"
                        v-model="servidores.cargo"
                        id="inputCargoServidor"
                        :options="cargos[i]"
                        label="nome"
                        placeholder="Digite o cargo do servidor"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="second">
                    <button
                      id="botaoCarregarDados"
                      class="btn-carregar-dados"
                      @click.prevent="buscarDados(servidores.cpf, i)"
                    >
                      Carregar dados do(a) servidor(a)
                    </button>
                    <button
                      v-if="servidores.cpf == '' || servidores.cpf == null"
                      type="button"
                      @click.prevent="novoServidor()"
                      class="servidorButton"
                    >
                      + Novo(a) servidor(a)
                    </button>
                  </div>
                </div>
              </div>

              <!-- Novo Servidor -->
              <div
                class="divNovoServidor"
                v-show="servidores.salvo !== true"
                v-if="flagServidores == true"
              >
                <div class="modal-servidor">
                  <div class="modal-mask">
                    <div class="modal-container">
                      <div class="modal-header">
                        <h2>Cadastro de novo(a) servidor(a)</h2>
                        <MsgErroModal
                          v-if="erroModal && msgErrosModal"
                          :mensagensModal="msgErrosModal"
                          :erroModal="erroModal"
                          @fechar-erro="fecharErro"
                        />
                      </div>
                      <div class="modal-body">
                        <div class="gridbox-1">
                          <!-- CPF do servidor -->
                          <div class="item">
                            <label>{{ possuiAsterisco("CPF:") }}</label>
                            <the-mask
                              id="inputCpfServidor"
                              :mask="['###.###.###-##']"
                              placeholder="Digite o CPF do(a) novo(a) servidor(a)"
                              v-model="servidores.cpf"
                            />
                          </div>
                          <div class="item">
                            <button
                              id="botaoCarregarDados"
                              class="btn-carregar-dados"
                              @click.prevent="
                                buscarServidorCadastrado(servidores.cpf, i)
                              "
                            >
                              Carregar dados do(a) novo(a) servidor(a)
                            </button>
                          </div>
                        </div>
                        <!-- Nome do servidor -->
                        <div class="item" v-if="servidores.nome !== null">
                          <label>{{ possuiAsterisco("Nome:") }}</label>
                          <input
                            id="inputNomeServidor"
                            type="text"
                            placeholder="Digite o nome do novo servidor"
                            v-model="servidores.nome"
                          />
                        </div>
                        <!-- Cargo do servidor -->
                        <div
                          class="item cargo"
                          v-if="servidores.cargo !== null"
                        >
                          <label>{{ possuiAsterisco("Cargo:") }}</label>

                          <v-select
                            class=".vselect-sem-borda .vselect-static"
                            v-model="servidores.cargo"
                            id="inputCargoServidor"
                            :options="paginatedCargos"
                            label="nome"
                            placeholder="Digite o cargo do servidor"
                            :filterable="false"
                            @search="onSearch"
                          >
                            <li slot="list-footer" class="pagination">
                              <button
                                :disabled="!hasPrevPage"
                                @click.prevent="offset -= limit"
                              >
                                Anterior
                              </button>
                              <button
                                :disabled="!hasNextPage"
                                @click.prevent="offset += limit"
                              >
                                Próxima
                              </button>
                            </li>
                          </v-select>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          id="btn-salvar-servidor"
                          class="btn-salvar-servidor"
                          @click.prevent="salvarServidor(servidores, i)"
                        >
                          Salvar
                        </button>
                        <button
                          class="btn-fechar-servidor"
                          @click.prevent="fecharModalServidor(i)"
                        >
                          Fechar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { cloneDeep, tap } from "lodash";

import CessaoApi from "../../services/CessaoApi";
import ServidorApi from "../../services/ServidorApi";
import MsgErroModal from "../../components/MsgErroModal.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "MultiplosServidores",
  props: {
    erro: {
      type: Boolean,
    },
    msgErros: {
      type: Object,
    },
    ["value"]: {
      type: Array,
    },
  },
  components: {
    MsgErroModal,
    vSelect,
  },
  data() {
    return {
      servidores: [],
      cargos: [],
      cargosNovoServidor: [],
      carregando: true,
      cpf: null,
      nome: null,
      cargo: null,
      servidorNovo: null,
      salvo: null,
      flagServidores: false,
      erroModal: false,
      msgErrosModal: {
        servidor_cpf: "",
        servidor_nome: "",
        servidor_cargo: "",
        default: "",
      },
      cargosCadastrados: [],
      search: "",
      offset: 0,
      limit: 10,
    };
  },
  created() {
    this.cessaoApi = new CessaoApi();
    this.servidorApi = new ServidorApi();
    this.carregarServidores();
    this.carregarCargos();
    this.carregarCargosCadastrados();
  },
  computed: {
    local() {
      return this.value ? this.value : [];
    },
    filtered() {
      if (!this.search) return this.cargosNovoServidor;
      return this.cargosNovoServidor.filter((cargo) =>
        cargo.nome.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    paginatedCargos() {
      return this.filtered.slice(this.offset, this.limit + this.offset);
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit;
      return Boolean(
        this.filtered.slice(nextOffset, this.limit + nextOffset).length
      );
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit;
      return Boolean(
        this.filtered.slice(prevOffset, this.limit + prevOffset).length
      );
    },
  },
  methods: {
    onSearch(query) {
      this.search = query;
      this.offset = 0;
    },
    async carregarServidores() {
      try {
        if (this.carregandoFiltro && !this.erroFiltro) return;

        this.erroFiltro = false;

        const dadosServidores = await this.cessaoApi.getServidores();
        this.servidores = dadosServidores;
      } catch (err) {
        console.log("carregarServidores", err);
        this.carregandoFiltro = false;
        this.erroFiltro = true;
        this.servidores = [];
      }
    },
    async carregarCargosServidor(idServidor, position) {
      try {
        if (this.carregandoFiltro && !this.erroFiltro) return;

        this.erroFiltro = false;
        const cargo = await this.servidorApi.getCargosServidor(idServidor);
        console.log("Cargo: ", cargo);
        this.cargos.push(cargo);
      } catch (err) {
        console.log("carregarCargos", err);
        this.carregandoFiltro = false;
        this.erroFiltro = true;
        this.cargos[position] = [];
      }
    },
    async carregarCargos() {
      try {
        if (this.carregandoFiltro && !this.erroFiltro) return;

        this.erroFiltro = false;

        const dadosCargosNovoServidor = await this.cessaoApi.getCargos();
        this.cargosNovoServidor = dadosCargosNovoServidor;
      } catch (err) {
        console.log("carregarCargos", err);
        this.carregandoFiltro = false;
        this.erroFiltro = true;
        this.cargosNovoServidor = [];
      }
    },
    async carregarCargosCadastrados() {
      try {
        if (this.carregandoFiltro && !this.erroFiltro) return;

        this.erroFiltro = false;

        const dadosCargosCadastrados = await this.servidorApi.getCargos();
        this.cargosCadastrados = dadosCargosCadastrados;
      } catch (err) {
        console.log("carregarCargosCadastrados", err);
        this.carregandoFiltro = false;
        this.erroFiltro = true;
        this.cargosCadastrados = [];
      }
    },
    adicionarServidores() {
      this.$emit(
        "input",
        tap(cloneDeep(this.local), (v) =>
          v.push({
            id: null,
            cpf: null,
            nome: null,
            cargo: null,
            salvo: null,
          })
        )
      );
    },
    novoServidor() {
      this.flagServidores = true;
    },
    fecharModalServidor(position) {
      let clone;
      clone = tap(cloneDeep(this.local), (v) => {
        v[position] = {
          ...v[position],
          id: null,
          cpf: null,
          nome: null,
          servidorNovo: null,
          salvo: null,
          cargo: null,
        };
      });
      this.$emit("input", clone);
      this.flagServidores = false;
      this.erroModal = false;
      this.msgErrosModal.servidor_cpf = "";
      this.msgErrosModal.servidor_nome = "";
      this.msgErrosModal.servidor_cargo = "";
      this.msgErrosModal.default = "";
    },
    fecharErro() {
      this.erro = false;
      this.erroModal = false;
    },
    zerarMensagens() {
      this.msgErrosModal.servidor_cpf = "";
      this.msgErrosModal.servidor_nome = "";
      this.msgErrosModal.servidor_cargo = "";
      this.msgErrosModal.default = "";
    },
    salvarServidor(servidor, position) {
      this.zerarMensagens();

      if (servidor.cpf == null || servidor.cpf == "") {
        this.erroModal = true;
        this.msgErrosModal.servidor_cpf =
          "O CPF do campo servidor não foi preenchido.";
      } else {
        this.erroModal = false;
        this.msgErrosModal.servidor_cpf = "";
      }

      if (servidor.nome == null || servidor.nome == "") {
        this.erroModal = true;
        this.msgErrosModal.servidor_nome =
          "O nome do campo servidor não foi preenchido.";

        if (servidor.cargo == null || servidor.cargo == "") {
          this.erroModal = true;
          this.msgErrosModal.servidor_cargo =
            "O cargo do campo servidor não foi preenchido.";
        } else {
          this.erroModal = false;
          this.msgErrosModal.servidor_cargo = "";
        }
      } else {
        if (servidor.cargo == null || servidor.cargo == "") {
          this.erroModal = true;
          this.msgErrosModal.servidor_cargo =
            "O cargo do campo servidor não foi preenchido.";
          this.msgErrosModal.servidor_nome = "";
        } else {
          let clone;
          clone = tap(cloneDeep(this.local), (v) => {
            v[position] = {
              ...v[position],
              salvo: true,
            };
          });
          this.$emit("input", clone);
          this.flagServidores = false;
        }
      }
    },
    buscarServidorCadastrado(cpf, position) {
      let flag = 0;

      this.servidores.map((servidor) => {
        if (servidor.cpf == cpf) {
          flag = 1;
          return;
        }
      });

      if (flag == 0) {
        this.buscarDadosNovoServidor(cpf, position);
      } else {
        let botaoSalvarServidor = document.getElementById(
          "btn-salvar-servidor"
        );
        botaoSalvarServidor.disabled = true;
        botaoSalvarServidor.style.cursor = "not-allowed";
        this.erroModal = true;
        this.msgErrosModal.default =
          "Esse(a) servidor(a) já está cadastrado(a).";
        let clone;
        clone = tap(cloneDeep(this.local), (v) => {
          v[position] = {
            ...v[position],
            cpf: null,
            nome: null,
            cargo: null,
            servidorNovo: null,
            salvo: null,
          };
        });
        this.$emit("input", clone);
      }
    },
    async buscarDadosNovoServidor(cpf, position) {
      try {
        this.carregando = true;
        this.erroModal = false;

        this.zerarMensagens();

        const rhNet = await this.servidorApi.getDadosRhNet(cpf);
        this.dadosRhNet = rhNet;

        const cargoCarregado = {
          id: null,
          nome: this.dadosRhNet.cargo,
        };

        this.cargosCadastrados.map((cargoCadastrado) => {
          if (cargoCarregado.nome == cargoCadastrado.nome) {
            cargoCarregado.id = cargoCadastrado.id;
          }
        });

        let clone;
        clone = tap(cloneDeep(this.local), (v) => {
          v[position] = {
            ...v[position],
            nome: this.dadosRhNet.nome,
            cargo: cargoCarregado,
            servidorNovo: true,
          };
        });
        this.$emit("input", clone);
      } catch (err) {
        if (this.servidorApi.isCancel(err)) {
          return;
        }
        console.log(err);
        this.carregando = false;
        this.erroModal = true;

        let clone;
        clone = tap(cloneDeep(this.local), (v) => {
          v[position] = {
            ...v[position],
            nome: "",
            cargo: "",
            servidorNovo: true,
          };
        });
        this.$emit("input", clone);

        const { response } = err;
        if (response && response.data) {
          this.msgErrosModal.default = response.data.mensagem;
        } else {
          this.msgErrosModal.default =
            "Houve um erro ao salvar o registro. Por favor tente novamente.";
        }
      }
    },
    buscarDados(cpf, position) {
      try {
        this.carregando = true;
        this.$emit("atualizarErro", false);

        this.zerarMensagens();

        let marcador = 0;
        let clone;

        this.servidores.map((servidor) => {
          if (servidor.cpf == cpf) {
            clone = tap(cloneDeep(this.local), (v) => {
              v[position] = {
                ...v[position],
                id: servidor.id,
                nome: servidor.nome,
                cargo: [],
                servidorNovo: false,
                salvo: true,
              };
            });

            marcador = 1;
            this.carregarCargosServidor(servidor.id, position);
          }
        });

        if (marcador == 0) {
          this.msgErros.servidor =
            "Esse servidor não está cadastrado! Por favor, clique em novo servidor.";
          clone = tap(cloneDeep(this.local), (v) => {
            v[position] = {
              ...v[position],
              cpf: null,
            };
          });
          this.$emit("input", clone);
          this.$emit("atualizarErro", true);
        } else {
          this.$emit("input", clone);
        }
      } catch (err) {
        if (this.servidorApi.isCancel(err)) {
          return;
        }
        console.log(err);
        this.carregando = false;
        this.$emit("atualizarErro", true);

        const { response } = err;
        if (response && response.data) {
          this.msgErros.default = response.data.mensagem;
        } else {
          this.msgErros.default =
            "Houve um erro ao salvar o registro. Por favor tente novamente.";
        }
      }
    },
    removerServidor(servidores, i) {
      let clone;
      if (servidores.id) {
        clone = tap(cloneDeep(this.local), (v) => {
          v[i] = { ...v[i], _destroy: true };
        });
      } else {
        clone = tap(cloneDeep(this.local), (v) => v.splice(i, 1));
      }
      this.$emit("input", clone);
    },
    possuiAsterisco(texto) {
      return "* " + texto;
    },
  },
};
</script>

<style lang="scss" scoped>
.mensagem {
  display: none;
  color: red;
}
p#mensagem.mensagem {
  margin: 0;
}
.pagination {
  justify-content: space-evenly;
  margin: 0.5rem 0 0.3rem 0;

  button {
    width: 10rem;
    flex-grow: initial;
    background-color: #00a339;
  }
}
::v-deep .v-select {
  .vs__dropdown-toggle {
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .vs__selected {
    font-size: 0.9rem;
    color: #333;
  }

  .vs__search {
    font-size: 14px;
    border: none;
  }

  .vs__dropdown-menu {
    border: 1px solid #ccc;
    border-radius: 4px;
    position: static;
    font-size: 0.9rem;
  }

  .vs__dropdown-option {
    padding: 5px 12px;
  }

  .vs__dropdown-option--highlight {
    background-color: #007bff;
    color: #fff;
  }
}
@media screen and (min-width: "1066px") {
  #servidores {
    width: -webkit-fill-available;
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
        margin: 0 0.3rem 0 0;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .caixa-form-servidores {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 0;
      width: -webkit-fill-available;

      .divServidores {
        display: grid;
        grid-template: "a b";
        grid-template-columns: 0.01fr 0.99fr;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0.3rem;

        .botao-excluir {
          min-width: max-content;
        }

        .botao-excluir:hover {
          img {
            padding: 0.3rem;
          }
        }

        .dados {
          display: grid;
          align-items: center;
          gap: 1rem;

          input {
            padding: 0.2rem 0.3rem;
            border: none;
          }

          .first {
            display: flex;
            gap: 3rem;
            .cpf {
              display: grid;
              align-items: center;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: 17rem;
                border: none;
              }
            }
            .nome {
              display: grid;
              align-items: center;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: 17rem;
                border: none;
              }
            }
            .cargo {
              display: grid;
              align-items: center;
              width: -webkit-fill-available;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: 17rem;
                border: none;
              }
            }
            button {
              padding: 0.3rem 0.5rem;
              margin: 0;
            }
          }
          .second {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .servidorButton {
              display: inline-block;
              border: none;
              background-color: transparent;
              color: #00a339;
              font-weight: bold;
              font-size: 0.8rem;
              margin-left: 0.5rem;
              cursor: pointer;
            }
            .servidorButton:hover {
              text-decoration: underline;
            }

            button {
              padding: 0.3rem 0.5rem;
              margin: 0;
            }
          }
        }

        .limite {
          display: grid;
          grid-template: "a b";
          grid-template-columns: 0.35fr 0.15fr 0.75fr;
          gap: 0.5rem;
          align-items: center;
          label {
            margin: 0;
          }
          input {
            max-width: 5rem;
            border: none;
          }
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          padding: 0.2rem;
          height: 1.4rem;
          display: inline-block;
        }
        button {
          padding-left: 0.2rem;
          padding-right: 0.05rem;
          border-radius: 1rem;
          border: none;
        }
        p {
          margin-bottom: 0%;
          margin-top: 1rem;
        }
      }

      input {
        border-radius: 0.5rem;
        border: none;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        border-radius: 0.5rem;
        border: none;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .custom-file-upload-disable {
        border-radius: 0.5rem;
        border: none grey;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        background-color: white;
        color: grey;
      }

      .item-servidores {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          margin: 0;
          display: inline;
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
          border: none;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
}
@media screen and (min-width: "769px") and (max-width: "1065px") {
  #servidores {
    width: -webkit-fill-available;
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
        margin: 0 0.3rem 0 0;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .caixa-form-servidores {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 0;
      width: -webkit-fill-available;

      .divServidores {
        display: grid;
        grid-template: "a b";
        grid-template-columns: 0.01fr 0.99fr;
        align-items: center;
        gap: 1rem;
        padding: 1rem 0.3rem;

        .botao-excluir {
          min-width: max-content;
        }

        .botao-excluir:hover {
          img {
            padding: 0.3rem;
          }
        }

        .dados {
          display: grid;
          align-items: center;
          gap: 1rem;

          input {
            padding: 0.2rem 0.3rem;
            border: none;
          }

          .first {
            display: flex;
            gap: 3rem;
            .cpf {
              display: grid;
              align-items: center;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: 17rem;
                border: none;
              }
            }
            .nome {
              display: grid;
              align-items: center;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: 17rem;
                border: none;
              }
            }
            .cargo {
              display: grid;
              align-items: center;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: 17rem;
                border: none;
              }
            }
            button {
              padding: 0.3rem 0.5rem;
              margin: 0;
            }
          }
          .second {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .servidorButton {
              display: inline-block;
              border: none;
              background-color: transparent;
              color: #00a339;
              font-weight: bold;
              font-size: 0.8rem;
              margin-left: 0.5rem;
              cursor: pointer;
            }
            .servidorButton:hover {
              text-decoration: underline;
            }

            button {
              padding: 0.3rem 0.5rem;
              margin: 0;
            }
          }
        }

        .limite {
          display: grid;
          grid-template: "a b";
          grid-template-columns: 0.35fr 0.15fr 0.75fr;
          gap: 0.5rem;
          align-items: center;
          label {
            margin: 0;
          }
          input {
            max-width: 5rem;
            border: none;
          }
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          padding: 0.2rem;
          height: 1.4rem;
          display: inline-block;
        }
        button {
          padding-left: 0.2rem;
          padding-right: 0.05rem;
          border-radius: 1rem;
          border: none;
        }
        p {
          margin-bottom: 0%;
          margin-top: 1rem;
        }
      }

      input {
        border-radius: 0.5rem;
        border: none;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        border-radius: 0.5rem;
        border: none;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .custom-file-upload-disable {
        border-radius: 0.5rem;
        border: none grey;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        background-color: white;
        color: grey;
      }

      .item-servidores {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          margin: 0;
          display: inline;
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
          border: none;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
}
@media screen and (min-width: "1024px") {
  #servidores {
    .caixa-form-servidores {
      .divServidores {
        .dados {
          .first {
            display: flex;
            gap: 0.5rem;
            justify-content: space-between;
            .cpf {
              display: grid;
              align-items: center;
              width: -webkit-fill-available;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: -webkit-fill-available;
                border: none;
              }
            }
            .nome {
              display: grid;
              align-items: center;
              width: -webkit-fill-available;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: -webkit-fill-available;
                border: none;
              }
            }
            .cargo {
              display: grid;
              align-items: center;
              width: -webkit-fill-available;
              label {
                width: max-content;
                margin: 0;
              }
              .vselect {
                display: block;
                width: -webkit-fill-available;
                font-size: 0.9rem;
              }
            }
            button {
              padding: 0.3rem 0.5rem;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: "769px") and (max-width: "1023px") {
  #servidores {
    .caixa-form-servidores {
      .divServidores {
        .dados {
          .first {
            display: flex;
            gap: 1.5rem;
            flex-wrap: wrap;
            justify-content: space-between;
            .cpf {
              display: grid;
              align-items: center;
              width: 45%;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: -webkit-fill-available;
                border: none;
              }
            }
            .nome {
              display: grid;
              align-items: center;
              width: 45%;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: -webkit-fill-available;
                border: none;
              }
            }
            .cargo {
              display: grid;
              align-items: center;
              width: -webkit-fill-available;
              label {
                width: max-content;
                margin: 0;
              }
              .vselect {
                display: block;
                width: -webkit-fill-available;
                font-size: 0.9rem;
              }
            }
            button {
              padding: 0.3rem 0.5rem;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: "60px") and (max-width: "768px") {
  #servidores {
    width: -webkit-fill-available;
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
        margin: 0 0.3rem 0 0;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .caixa-form-servidores {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 0;
      width: -webkit-fill-available;

      .divServidores {
        display: grid;
        grid-template: "a b";
        grid-template-columns: 0.01fr 0.99fr;
        align-items: center;
        gap: 0.3rem;
        padding: 1rem 0.3rem;

        .botao-excluir {
          min-width: max-content;
        }

        .botao-excluir:hover {
          img {
            padding: 0.3rem;
          }
        }

        .dados {
          display: grid;
          align-items: center;
          gap: 1rem;

          input {
            padding: 0.2rem 0.3rem;
            border: none;
          }

          .first {
            display: flex;
            gap: 1.5rem;
            flex-wrap: wrap;
            justify-content: space-between;
            .cpf {
              display: grid;
              align-items: center;
              width: -webkit-fill-available;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: -webkit-fill-available;
                font-size: 0.8rem;
                border: none;
              }
            }
            .nome {
              display: grid;
              align-items: center;
              width: -webkit-fill-available;
              label {
                width: max-content;
                margin: 0;
              }
              input {
                display: block;
                width: -webkit-fill-available;
                font-size: 0.8rem;
                border: none;
              }
            }
            .cargo {
              display: grid;
              align-items: center;
              width: -webkit-fill-available;
              label {
                width: max-content;
                margin: 0;
              }
              .vselect {
                display: block;
                width: -webkit-fill-available;
                font-size: 0.8rem;
              }
            }
            button {
              padding: 0.3rem 0.5rem;
              margin: 0;
            }
          }
          .second {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .servidorButton {
              display: inline-block;
              border: none;
              background-color: transparent;
              color: #00a339;
              font-weight: bold;
              font-size: 0.8rem;
              margin-left: 0.5rem;
              cursor: pointer;
            }
            .servidorButton:hover {
              text-decoration: underline;
            }

            button {
              padding: 0.3rem 0.5rem;
              margin: 0;
            }
          }
        }

        .limite {
          display: grid;
          grid-template: "a b";
          grid-template-columns: 0.35fr 0.15fr 0.75fr;
          gap: 0.5rem;
          align-items: center;
          label {
            margin: 0;
          }
          input {
            max-width: 5rem;
            border: none;
          }
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          padding: 0.2rem;
          height: 1.4rem;
          display: inline-block;
        }
        button {
          padding-left: 0.2rem;
          padding-right: 0.05rem;
          border-radius: 1rem;
          border: none;
        }
        p {
          margin-bottom: 0%;
          margin-top: 1rem;
        }
      }

      input {
        border-radius: 0.5rem;
        border: none;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        border-radius: 0.5rem;
        border: none;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .custom-file-upload-disable {
        border-radius: 0.5rem;
        border: none grey;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        background-color: white;
        color: grey;
      }

      .item-servidores {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          margin: 0;
          display: inline;
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
          border: none;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
}
.modal-servidor {
  visibility: visible;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: table;
  transition: opacity 0.3s ease;

  .modal-mask {
    border-radius: 1rem;
    position: relative;
    z-index: 1000;
    top: 12vh;
    min-width: 20rem;
    max-width: 40vw;
    height: auto;
    background-color: #f2f2f2;
    transition: opacity 0.3s ease;
    margin: 0 auto;
  }

  .modal-container {
    display: grid;
    align-content: space-around;
    height: 100%;
    width: auto;
    padding: 0.3rem;
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
    font-family: "Montserrat-Medium";

    .modal-header {
      height: fit-content;
      width: -webkit-fill-available;
      margin: 0.5rem 0.5rem 0.3rem 0.5rem;
      display: block;

      h2 {
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
        color: #00a339;
      }
    }

    .modal-body {
      border: 1px solid gray;
      border-radius: 1rem;
      text-align: left;

      .item {
        margin: 0.5rem 1.5rem;
        label {
          font-size: 1rem;
          font-weight: bold;
          margin-top: 0.3rem;
          margin-bottom: 0.2rem;
          margin-left: 1rem;
          color: #005516;
        }
      }
    }

    .modal-footer {
      margin: 0.5rem 0 0 0;
      place-content: space-evenly;
      font-size: 0.9rem;

      .btn-salvar-servidor {
        border: none;
        border-radius: 0.5rem;
        width: 25%;
        height: 1.8rem;
        background-color: #005516;
        color: #f2f2f2;
      }
      .btn-salvar-servidor:hover {
        background-color: #005517ce;
      }
      .btn-fechar-servidor {
        border: none;
        border-radius: 0.5rem;
        width: 25%;
        height: 1.8rem;
        background-color: rgb(203, 1, 1);
        color: #f2f2f2;
      }
      .btn-fechar-servidor:hover {
        background-color: rgba(203, 1, 1, 0.815);
      }
    }
  }
}
</style>
