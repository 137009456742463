<template>
  <div class="wrapper">
    <div class="topo-header centro">
      <botao-voltar nomeRota="Cessao" />
      <h1 v-if="this.renovar !== true">
        {{ cessaoForm.id ? "Editar" : "Nova" }} Cessão
      </h1>
      <h1 v-else>
        Renovar Cessão
      </h1>
    </div>
    <div class="tab-dados-basicos">
      <div class="form centro">
        <div class="modal-confirmacao">
          <div class="modal-mask-confirmacao">
            <div class="modal-container-confirmacao">
              <div class="modal-header-confirmacao">
                <h2>
                  Já existe uma cessão com essas características cadastrada para
                  esse servidor!
                </h2>
                <h3>Tem certeza que deseja prosseguir?</h3>
              </div>
              <div class="modal-footer-confirmacao">
                <button
                  class="btn-confirmar-confirmacao"
                  @click="confirmarEnvio()"
                >
                  Sim
                </button>
                <button
                  class="btn-cancelar-confirmacao"
                  @click="opcoesConfirmar()"
                >
                  Não
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Mensagens de erro -->
        <MsgErroFormularios
          v-if="erro && msgErros"
          :mensagens="msgErros"
          :erro="erro"
          @fechar-erro="fecharErro"
        />
        <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>
        <form @submit.prevent="enviarForm">
          <div class="caixa-form item">
            <div>
              <!-- Servidor -->
              <div v-if="nomeRotaAtual == 'CessaoNew'">
                <MultiplosServidores
                  v-model="cessaoFormm.servidores_attributes"
                  :msgErros="msgErros"
                  :erro="erro"
                  @atualizarErro="atualizarErro"
                />
              </div>
              <div class="item" v-else>
                <div class="flexbox-2" v-if="flagServidores == false">
                  <div class="gridbox-1">
                    <label for="selectServidor">{{
                      possuiAsterisco("Servidor(a):")
                    }}</label>
                    <v-select
                      class="vselect"
                      v-model="cessaoForm.servidor_id"
                      id="selectServidor"
                      :options="servidores"
                      :reduce="(nome) => nome.id"
                      label="nome"
                      placeholder="Todos"
                      disabled
                    >
                    </v-select>
                  </div>
                  <div class="gridbox-1">
                    <label for="inputCPF">{{
                      possuiAsterisco("CPF do(a) servidor(a):")
                    }}</label>
                    <the-mask
                      :mask="['###.###.###-##']"
                      placeholder="Digite o CPF do(a) servidor(a)"
                      v-model="servidorDaCessao.cpf"
                      disabled
                    />
                  </div>
                  <div class="gridbox-1">
                    <label for="inputCargoServidor">{{
                      possuiAsterisco("Cargo do(a) servidor(a):")
                    }}</label>
                    <v-select
                      class="vselect"
                      v-model="cessaoForm.cargo_id"
                      id="inputCargoServidor"
                      :options="cargosServidorEdit"
                      :reduce="(nome) => nome.id"
                      label="nome"
                      placeholder="Digite o cargo do servidor"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="gridbox-1 servidorForm" v-else>
                  <!-- CPF do servidor -->
                  <div class="item">
                    <label>{{ possuiAsterisco("CPF:") }}</label>
                    <the-mask
                      :mask="['###.###.###-##']"
                      placeholder="Digite o CPF do(a) servidor(a)"
                      v-model="servidorForm.cpf"
                    />
                  </div>
                  <div class="item">
                    <button
                      class="btn-carregar-dados"
                      @click.prevent="buscarDados(servidorForm.cpf)"
                    >
                      Carregar nome
                    </button>
                  </div>
                  <!-- Nome do servidor -->
                  <div class="item">
                    <label>{{ possuiAsterisco("Nome:") }}</label>
                    <input
                      type="text"
                      placeholder="Digite o nome do(a) servidor(a)"
                      v-model="servidorForm.nome"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Órgãos de origem e de destino -->
            <div class="flexbox-2">
              <!-- Órgão de origem -->
              <div class="item">
                <label for="selectOrgaoOrigem"
                  ><b>{{ possuiAsterisco("Órgão de origem:") }}</b></label
                >
                <v-select
                  class="vselect"
                  v-model="selectedOrgaoOrigem"
                  id="selectedOrgaoOrigem"
                  :options="paginated"
                  label="nome"
                  placeholder="Todos"
                  :filterable="false"
                  @search="onSearch"
                >
                  <li slot="list-footer" class="pagination">
                    <button
                      :disabled="!hasPrevPage"
                      @click.prevent="offset -= limit"
                    >
                      Anterior
                    </button>
                    <button
                      :disabled="!hasNextPage"
                      @click.prevent="offset += limit"
                    >
                      Próxima
                    </button>
                  </li>
                </v-select>
              </div>
              <!-- Órgão de destino -->
              <div class="item">
                <label for="selectOrgaoDestino"
                  ><b>{{ possuiAsterisco("Órgão de destino:") }}</b></label
                >
                <v-select
                  class="vselect"
                  v-model="selectedOrgaoDestino"
                  id="selectedOrgaoDestino"
                  :options="paginated"
                  label="nome"
                  placeholder="Todos"
                  :filterable="false"
                  @search="onSearch"
                >
                  <li slot="list-footer" class="pagination">
                    <button
                      :disabled="!hasPrevPage"
                      @click.prevent="offset -= limit"
                    >
                      Anterior
                    </button>
                    <button
                      :disabled="!hasNextPage"
                      @click.prevent="offset += limit"
                    >
                      Próxima
                    </button>
                  </li>
                </v-select>
              </div>
            </div>
            <div class="flexbox-2">
              <!-- Tempo indeterminado -->
              <div class="item">
                <label
                  ><b>{{ possuiAsterisco("Tempo indeterminado?") }}</b></label
                >
                <div class="radioContainer">
                  <div class="radioTrue">
                    <input
                      type="radio"
                      id="true-tempo-indeterminado"
                      :value="true"
                      v-model="cessaoForm.termino_indeterminado"
                    />Sim
                  </div>
                  <div class="radioFalse">
                    <input
                      type="radio"
                      id="false-tempo-indeterminado"
                      :value="false"
                      v-model="cessaoForm.termino_indeterminado"
                    />Não
                  </div>
                </div>
              </div>
              <!-- Renovação -->
              <div class="item">
                <label
                  ><b>{{ possuiAsterisco("Renovação?") }}</b></label
                >
                <div
                  class="radioContainer"
                  v-if="this.$route.params.renovar == true"
                >
                  <div class="radioTrue">
                    <input
                      type="radio"
                      id="true-renovar"
                      :value="true"
                      v-model="cessaoForm.renovar"
                      disabled
                    />
                    Sim
                  </div>
                  <div class="radioFalse">
                    <input
                      type="radio"
                      id="false-renovar"
                      :value="false"
                      v-model="cessaoForm.renovar"
                      disabled
                    />
                    Não
                  </div>
                </div>
                <div class="radioContainer" v-else>
                  <div class="radioTrue">
                    <input
                      type="radio"
                      id="true-renovar"
                      :value="true"
                      v-model="cessaoForm.renovar"
                    />
                    Sim
                  </div>
                  <div class="radioFalse">
                    <input
                      type="radio"
                      id="false-renovar"
                      :value="false"
                      v-model="cessaoForm.renovar"
                    />
                    Não
                  </div>
                </div>
              </div>
            </div>
            <div class="flexbox-2">
              <!-- Data de inicio da cessão -->
              <div class="item">
                <label><b>Data de início da cessão:</b></label>
                <date-picker
                  v-model="cessaoForm.data_inicio"
                  format="DD/MM/YYYY"
                  type="date"
                  placeholder="Selecione uma data de início da cessão"
                >
                </date-picker>
              </div>
              <!-- Data de término da cessão -->
              <div
                class="item"
                v-if="this.cessaoForm.termino_indeterminado !== true"
              >
                <label><b>Data de término da cessão:</b></label>
                <date-picker
                  v-model="cessaoForm.data_termino"
                  format="DD/MM/YYYY"
                  type="date"
                  placeholder="Selecione uma data de término da cessão"
                >
                </date-picker>
              </div>
            </div>
            <div class="flexbox-3">
              <!-- Ônus -->
              <div class="item">
                <label for="selectOnus"
                  ><b>{{ possuiAsterisco("Ônus:") }}</b></label
                >
                <v-select
                  class="vselect"
                  v-model="cessaoForm.onus_id"
                  id="selectOnus"
                  :options="onus"
                  :reduce="(nome) => nome.id"
                  label="nome"
                >
                </v-select>
              </div>
              <!-- Status -->
              <div class="item">
                <label for="selectStatus"
                  ><b>{{ possuiAsterisco("Status:") }}</b></label
                >
                <v-select
                  class="vselect"
                  v-model="cessaoForm.status_id"
                  id="selectStatus"
                  :options="status"
                  :reduce="(nome) => nome.id"
                  label="nome"
                >
                </v-select>
              </div>
              <!-- FC -->
              <div class="item">
                <label for="selectFc"><b>Cargo / FC:</b></label>
                <v-select
                  class="vselect"
                  v-model="cessaoForm.fc"
                  id="selectFc"
                  :options="fc"
                  label="nome"
                >
                </v-select>
              </div>
            </div>
            <!-- Retorno -->
            <div v-if="this.renovar == false">
              <div class="item radio" v-if="this.nomeRotaAtual == 'CessaoEdit'">
                <label><b>Retorno:</b></label>
                <div class="radioContainer">
                  <div class="radioTrue">
                    <input
                      type="radio"
                      name="opcaoRetornoTrue"
                      :value="true"
                      v-model="cessaoForm.retorno"
                    />Sim
                  </div>
                  <div class="radioFalse">
                    <input
                      type="radio"
                      name="opcaoRetornoFalse"
                      :value="false"
                      v-model="cessaoForm.retorno"
                    />Não
                  </div>
                </div>
              </div>
              <div class="item" v-if="cessaoForm.retorno == true">
                <div class="flexbox-2">
                  <div class="item">
                    <!--Data de retorno-->
                    <label
                      ><b>{{ possuiAsterisco("Data de retorno:") }}</b></label
                    >
                    <date-picker
                      v-model="cessaoForm.data_retorno"
                      format="DD/MM/YYYY"
                      type="date"
                      placeholder="Selecione uma data de retorno"
                    >
                    </date-picker>
                  </div>
                  <div class="item">
                    <!--Número do processo SEI do retorno-->
                    <label
                      ><b>{{
                        possuiAsterisco("Número do processo no SEI do retorno:")
                      }}</b></label
                    >
                    <input
                      type="number"
                      placeholder="Digite o número do processo SEI do retorno"
                      v-model="cessaoForm.numero_processo_sei_retorno"
                    />
                  </div>
                </div>
                <!--Observação do retorno-->
                <label><b>Observação do retorno:</b></label>
                <textarea
                  v-model="cessaoForm.observacao_retorno"
                  name="body"
                  class="textarea"
                  id="body"
                  @input="resize($event)"
                  placeholder="Digite aqui, caso haja alguma observação para o retorno"
                >
                </textarea>
              </div>
            </div>
            <!-- Convênio -->
            <div class="item radio">
              <label
                ><b>{{
                  possuiAsterisco("Essa cessão possui convênio?")
                }}</b></label
              >
              <div class="radioContainer">
                <div class="radioTrue">
                  <input
                    type="radio"
                    name="opcao"
                    :value="true"
                    v-model="flagConvenio"
                  />Sim
                </div>
                <div class="radioFalse">
                  <input
                    type="radio"
                    name="opcao"
                    :value="false"
                    v-model="flagConvenio"
                  />Não
                </div>
              </div>
            </div>
            <div class="item" v-if="flagConvenio == true">
              <label for="selectConvenio"
                ><b>{{ possuiAsterisco("Convênio:") }}</b></label
              >
              <v-select
                class="vselect"
                v-model="cessaoForm.convenio_id"
                id="selectConvenio"
                :options="convenios"
                :reduce="(nome) => nome.id"
                label="nome"
                placeholder="Todos"
                @input="temObservacaoEFaixa(cessaoForm.convenio_id)"
              >
              </v-select>
              <div
                class="observacaoConvenio"
                v-if="this.flagObservacao == true"
              >
                <p>
                  Observação do convênio:
                  <span>{{ convenioObservacao }}</span>
                </p>
              </div>
              <div class="faixaConvenio" v-if="flagFaixa == true">
                <label for="selectFaixaConvenio"
                  ><b>{{ possuiAsterisco("Faixa(s) do convênio:") }}</b></label
                >
                <v-select
                  class="vselect"
                  v-model="cessaoForm.faixa_convenio_id"
                  id="selectFaixaConvenio"
                  :options="faixasConvenio"
                  :reduce="(nome) => nome.id"
                  label="nome"
                  placeholder="Todos"
                  @input="retornarLimite(cessaoForm.faixa_convenio_id)"
                >
                </v-select>
                <div class="limiteFaixaConvenio" v-if="flagLimite == true">
                  <p>
                    Limite restante da faixa:
                    <span v-if="cessaoForm.faixa_convenio_id !== null">{{
                      this.limiteFaixa - this.servidoresNaFaixa
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <!--<div class="flexbox-2">
                            <div class="item">
                                <label>{{ possuiAsterisco("Data de entrada do processo:") }}</label>
                                <date-picker
                                    v-model="cessaoForm.data_entrada_processo"
                                    format="DD/MM/YYYY"
                                    type="date"
                                    placeholder="Selecione uma data de entrada do processo"
                                >
                                </date-picker>
                            </div>
                        </div>-->
            <!-- Número do processo no SEI -->
            <div class="item">
              <label
                ><b>{{
                  possuiAsterisco("Número do processo no SEI:")
                }}</b></label
              >
              <input
                type="number"
                placeholder="Digite o número do processo"
                v-model="cessaoForm.numero_processo_sei"
              />
            </div>
            <!-- Observação -->
            <div class="item">
              <label><b>Observação:</b></label>
              <textarea
                v-model="cessaoForm.observacao"
                name="body"
                class="textarea"
                id="body"
                @input="resize($event)"
                placeholder="Digite aqui, caso haja alguma observação"
              >
              </textarea>
            </div>
            <!-- Botão de salvar -->
            <div class="bt-salvar">
              <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                <img
                  id="spinner-loading"
                  class="spinner-loading"
                  src="../../assets/imagens/loadingSpinner.gif"
                  alt="Spinner de carregamento"
                />
                <p id="conteudo-botao">
                  {{ cessaoForm.id ? "Atualizar" : "Criar" }}
                </p>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import DatePicker from "vue2-datepicker";
import BotaoVoltar from "../../components/BotaoVoltar.vue";
import MsgErroFormularios from "../MsgErroFormularios";
//import MsgErroModal from "../MsgErroModal";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { parseISO } from "date-fns";

import CessaoApi from "../../services/CessaoApi";
import ServidorApi from "../../services/ServidorApi";
import CargoApi from "../../services/CargoApi";
import CargoServidorApi from "../../services/CargoServidorApi";
import MultiplosServidores from "./MultiplosServidores.vue";

export default {
    name: "CessaoForm",
    props: {
        cessao: {
            type: Object,
            required: false,
        },
        renovar: {
            type: Boolean,
        },
    },
    data() {
        return {
            nomeRotaAtual: this.$router.currentRoute.name,
            carregando: true,
            erro: false,
            erroModal: false,
            flagModalConfirmacao: false,
            msgErros: {
                servidor: '',
                servidor_cpf: '',
                servidor_nome: '',
                servidor_cargo: '',
                orgao: '',
                orgao_origem: '',
                orgao_destino: '',
                termino_indeterminado: '',
                data: '',
                //data_inicio: '',
                //data_termino: '',
                //data_entrada_processo: '',
                onus: '',
                status: '',
                cargo: '',
                convenio: '',
                faixa_convenio: '',
                numero_processo_sei: '',
                default: '',
            },
            servidores: [],
            servidorDaCessao: [],
            orgaosConveniados: [],
            selectedOrgaoOrigem: null,
            selectedOrgaoDestino: null,
            search: '',
            offset: 0,
            limit: 10,
            convenios: [],
            convenioObservacao: "",
            faixasConvenios: [],
            faixasConvenio: [],
            limiteFaixa: null,
            servidoresNaFaixa: null,
            cessoesData: [],
            flagObservacao: false,
            flagFaixa: false,
            flagLimite: false,
            flagServidores: false,
            flagServidorNovo: false,
            onus: [],
            status: [],
            cargos: [],
            fc: ["Cargo", "FC"],
            flagConvenio: null,
            flagFaixaObrigatoria: null,
            cessaoAntiga: null,
            cessaoFormm: this.cessao
                ? {
                    servidores_attributes: this.cessao.servidores
                        ? this.cessao.servidores
                        : [],
                }
                : {
                    servidores_attributes: [],
                },
            cessaoForm: this.cessao
                ? {
                    id: this.cessao.id,
                    servidor_id: this.cessao.servidor_id,
                    orgao_origem_id: this.cessao.orgao_origem_id,
                    orgao_destino_id: this.cessao.orgao_destino_id,
                    termino_indeterminado: this.cessao.termino_indeterminado,
                    renovar: this.cessao.renovar,
                    data_inicio: this.cessao.data_inicio
                        ? parseISO(this.cessao.data_inicio)
                        : null,
                    data_termino: this.cessao.data_termino
                        ? parseISO(this.cessao.data_termino)
                        : null,
                    onus_id: this.cessao.onus_id,
                    status_id: this.cessao.status_id,
                    retorno: this.cessao.retorno,
                    data_retorno: this.cessao.data_retorno
                        ? parseISO(this.cessao.data_retorno)
                        : null,
                    numero_processo_sei_retorno: this.cessao.numero_processo_sei_retorno,
                    observacao_retorno: this.cessao.observacao_retorno,
                    numero_processo_sei: this.cessao.numero_processo_sei,
                    convenio_id: this.cessao.convenio_id,
                    faixa_convenio_id: this.cessao.faixa_convenio_id,
                    fc: this.cessao.fc,
                    observacao: this.cessao.observacao,
                    cargo_id: this.cessao.cargo_id,
                }
                : {
                    id: null,
                    servidor_id: null,
                    orgao_origem_id: null,
                    orgao_destino_id: null,
                    termino_indeterminado: null,
                    renovar: null,
                    data_inicio: null,
                    data_termino: null,
                    onus_id: null,
                    status_id: null,
                    retorno: null,
                    data_retorno: null,
                    numero_processo_sei_retorno: null,
                    observacao_retorno: null,
                    //data_entrada_processo: null,
                    numero_processo_sei: null,
                    convenio_id: null,
                    faixa_convenio_id: null,
                    fc: null,
                    observacao: null,
                    cargo_id: null,
                },
            cargoServidor: [],
            cargosServidorEdit: [],
        };
    },
    components: {
        BotaoVoltar,
        MsgErroFormularios,
        //MsgErroModal,
        DatePicker,
        vSelect,
        MultiplosServidores,
    },
    created() {
        this.cessaoApi = new CessaoApi();
        this.servidorApi = new ServidorApi();
        this.cargoApi = new CargoApi();
        this.cargoServidorApi = new CargoServidorApi();
        this.carregarServidores();
        this.carregarOrgaosConveniados();
        this.carregarConveniosEFaixas();
        this.carregarCessoes();
        this.carregarOnus();
        this.carregarStatus();
        if(this.cessaoForm.servidor_id !== null) {
            this.carregarCargosServidor(this.cessaoForm.servidor_id)
        }
    },
    computed: {
        filtered() {
            return this.orgaosConveniados.filter((orgao) =>
                orgao.nome.toLowerCase().includes(this.search.toLowerCase())
            )
        },
        paginated() {
            return this.filtered.slice(this.offset, this.limit + this.offset)
        },
        hasNextPage() {
            const nextOffset = this.offset + this.limit
            return Boolean(
                this.filtered.slice(nextOffset, this.limit + nextOffset).length
            )
        },
        hasPrevPage() {
            const prevOffset = this.offset - this.limit
            return Boolean(
                this.filtered.slice(prevOffset, this.limit + prevOffset).length
            )
        },
    },
    methods: {
        atualizarErro(novoErro) {
            this.erro = novoErro;
        },
        fecharErro() {
            this.erro = false;
            this.erroModal = false;
        },
        onSearch(query) {
            this.search = query
            this.offset = 0
        },
        novoServidor() {
            this.servidorForm.nome = null;
            this.servidorForm.cpf = null;
            this.servidorForm.cargo = null;
            this.cessaoForm.servidor_id = null;
            this.msgErrosModal.servidor_cpf = ''
            this.msgErrosModal.servidor_nome = ''
            this.msgErrosModal.servidor_cargo = ''
            this.msgErrosModal.default = ''
            this.erroModal = false;

            if(this.flagServidores == true) {
                this.flagServidores = false;
            } else {
                this.flagServidores = true;
            }

            if (this.flagServidorNovo == true) {
                this.flagServidorNovo = false;
            } else {
                this.flagServidorNovo = true;
            }
        },
        async buscarDadosNovoServidor(cpf) {
            try {
                this.carregando = true;
                this.erroModal = false;
                this.msgErrosModal.servidor_cpf = ''
                this.msgErrosModal.servidor_nome = ''
                this.msgErrosModal.servidor_cargo = ''
                this.msgErrosModal.default = ''

                const rhNet = await this.servidorApi.getDadosRhNet(cpf);
                this.dadosRhNet = rhNet

                this.servidores.map( (servidor) => {
                    if(servidor.cpf == this.servidorForm.cpf) {
                        this.erroModal = true;
                        this.msgErrosModal.default = 'Esse servidor já está cadastrado. Feche o modal e insira os dados no formulário inicial.'
                        this.servidorForm.nome = null
                        this.servidorForm.cargo = null
                        return;
                    } else {
                        if(this.erroModal == false) {
                            this.servidorForm.nome = this.dadosRhNet.nome
                            this.servidorForm.cargo = this.dadosRhNet.cargo
                        }
                    }
                })

            } catch (err) {
                if (this.servidorApi.isCancel(err)) {
                    return;
                }
                console.log(err);
                this.carregando = false;
                this.erroModal = true;

                if (this.servidorForm.nome == null && this.servidorForm.cargo == null) {
                    this.servidorForm.nome = ''
                    this.servidorForm.cargo = ''
                }

                const { response } = err;
                if (response && response.data) {
                    this.msgErrosModal.default = response.data.mensagem;
                } else {
                    this.msgErrosModal.default =
                        "Houve um erro ao salvar o registro. Por favor tente novamente.";
                }
            }
        },
        buscarDados(cpf) {
            let conteudoBotao = document.getElementById("conteudo-botao");
            const textoConteudoBotao = conteudoBotao.innerHTML;
            try {
                this.carregando = true;
                this.erro = false;
                this.msgErros.servidor = '';

                let marcador = 0;

                this.servidores.map((servidor) => {
                    if(servidor.cpf == cpf) {
                        this.servidorForm.nome = servidor.nome
                        this.servidorForm.cargo = servidor.cargo
                        this.cessaoForm.servidor_id = servidor.id;

                        marcador = 1;
                    }
                })
                if(marcador == 0) {
                    this.msgErros.servidor = "Esse servidor não está cadastrado! Por favor, clique em novo servidor."
                    this.erro = true;
                    this.servidorForm.cpf = null;
                }

            } catch (err) {
                if (this.servidorApi.isCancel(err)) {
                    return;
                }
                console.log(err);
                this.carregando = false;
                this.erro = true;

                this.mudarBotaoSalvar(textoConteudoBotao)

                const { response } = err;
                if (response && response.data) {
                    this.msgErros.servidor = response.data.mensagem;
                } else {
                    this.msgErros =
                        "Houve um erro ao salvar o registro. Por favor tente novamente.";
                }
            }
        },
        async carregarServidores() {
            try {
                if (this.carregandoFiltro && !this.erroFiltro) return;

                this.erroFiltro = false

                const dadosServidores = await this.cessaoApi.getServidores();
                this.servidores = dadosServidores

                for(const servidor of this.servidores) {
                    if(servidor.id == this.cessaoForm.servidor_id) {
                        this.servidorDaCessao = servidor;
                    }
                }

            } catch (err) {
                console.log("carregarServidores", err);
                this.carregandoFiltro = false;
                this.erroFiltro = true;
                this.servidores = [];
            }
        },
        async carregarOrgaosConveniados() {
            try {
                if (this.carregandoFiltro && !this.erroFiltro) return;

                this.erroFiltro = false

                const dadosOrgaosConveniados = await this.cessaoApi.getOrgaosConveniados();
                this.orgaosConveniados = dadosOrgaosConveniados

                this.orgaosConveniados.map((orgao) => {
                    if(orgao.sigla !== null) {
                        orgao.nome = orgao.sigla + " - " + orgao.nome
                    }
                })

                if (this.nomeRotaAtual == 'CessaoEdit') {
                    this.orgaosConveniados.map((orgao) => {
                        if (orgao.id == this.cessaoForm.orgao_origem_id) {
                            this.selectedOrgaoOrigem = orgao;
                        }
                        if (orgao.id == this.cessaoForm.orgao_destino_id) {
                            this.selectedOrgaoDestino = orgao;
                        }
                    })
                }
            } catch (err) {
                console.log("carregarOrgaosConveniados", err);
                this.carregandoFiltro = false;
                this.erroFiltro = true;
                this.orgaosConveniados = [];
            }
        },
        async carregarConveniosEFaixas() {
            try {
                if (this.carregandoFiltro && !this.erroFiltro) return;

                this.erroFiltro = false

                const dadosConvenios = await this.cessaoApi.getConvenios();
                this.convenios = dadosConvenios

                const dadosFaixasConvenios = await this.cessaoApi.getFaixasConvenios();
                this.faixasConvenios = dadosFaixasConvenios

                if(this.cessaoForm.convenio_id !== null) {
                    this.flagConvenio = true;
                    this.temObservacaoEFaixa(this.cessaoForm.convenio_id)
                }else {
                    if(this.nomeRotaAtual !== 'CessaoNew') {
                        this.flagConvenio = false;
                    }
                }

                if(this.cessaoForm.faixa_convenio_id == null) {
                    this.flagFaixa = false;
                }

            } catch (err) {
                console.log("carregarConveniosEFaixas", err);
                this.carregandoFiltro = false;
                this.erroFiltro = true;
                this.convenios = [];
                this.faixasConvenios = [];
            }
        },
        async carregarCessoes() {
            try {
                if (this.carregandoFiltro && !this.erroFiltro) return;

                this.erroFiltro = false

                const dadosCessoesData = await this.cessaoApi.getCessoesData();
                this.cessoesData = dadosCessoesData
            } catch (err) {
                console.log("carregarCessoes", err);
                this.carregandoFiltro = false;
                this.erroFiltro = true;
                this.cessoesData = [];
            }
        },
        async carregarOnus() {
            try {
                if (this.carregandoFiltro && !this.erroFiltro) return;

                this.erroFiltro = false

                const dadosOnus = await this.cessaoApi.getOnus();
                this.onus = dadosOnus
            } catch (err) {
                console.log("carregarOnus", err);
                this.carregandoFiltro = false;
                this.erroFiltro = true;
                this.onus = [];
            }
        },
        async carregarStatus() {
            try {
                if (this.carregandoFiltro && !this.erroFiltro) return;

                this.erroFiltro = false

                const dadosStatus = await this.cessaoApi.getStatus();
                this.status = dadosStatus
            } catch (err) {
                console.log("carregarStatus", err);
                this.carregandoFiltro = false;
                this.erroFiltro = true;
                this.status = [];
            }
        },
        async carregarCargosServidor(servidorId) {
            try {
                if (this.carregandoFiltro && !this.erroFiltro) return;

                this.erroFiltro = false

                const dadosCargosServidorEdit = await this.servidorApi.getCargosServidor(servidorId);
                this.cargosServidorEdit = dadosCargosServidorEdit
            } catch (err) {
                console.log("carregarCargosServidor", err);
                this.carregandoFiltro = false;
                this.erroFiltro = true;
                this.cargosServidorEdit = [];
            }
        },
        temObservacaoEFaixa(idConvenio) {
            this.faixasConvenio = [];

            if(idConvenio == null) {
                this.flagObservacao = false;
                this.flagFaixa = false;
            }else {
                this.convenios.map((convenio) => {
                    if(convenio.id == idConvenio) {
                        if(convenio.observacao !== null || convenio.observacao !== "") {
                            this.convenioObservacao = convenio.observacao;
                            this.flagObservacao = true;
                        }else {
                            this.flagObservacao = false;
                        }
                    }
                })

                this.faixasConvenios.map((faixaConvenio) => {
                    if(faixaConvenio.convenio_id == idConvenio) {
                        this.faixasConvenio.push(faixaConvenio);
                    }
                    this.flagFaixa = true;
                })
            }

            if(this.faixasConvenio.length == 0) {
                this.flagFaixaObrigatoria = false;
                this.flagFaixa = false;
                this.cessaoForm.faixa_convenio_id = null;
            } else {
                this.flagFaixaObrigatoria = true;
                this.flagFaixa = true;
            }
        },
        retornarLimite(idFaixaConvenio) {
            this.flagLimite = true;
            this.servidoresNaFaixa = 0;

            this.faixasConvenios.map((faixaConvenio) => {
                if (faixaConvenio.id == idFaixaConvenio) {
                    this.limiteFaixa = faixaConvenio.limite;
                }
            })
            this.cessoesData.map((cessao) => {
                var partes = cessao.data_termino.split("/");
                var dia = parseInt(partes[0], 10);
                var mes = parseInt(partes[1], 10);
                var ano = parseInt(partes[2], 10);

                var data = new Date(ano, mes - 1, dia);
                var anoDaData = data.getFullYear();

                var anoAtual = new Date().getFullYear();

                if(cessao.data_termino == null || anoDaData > anoAtual) {
                    if (cessao.faixa_convenio_id == idFaixaConvenio) {
                        this.servidoresNaFaixa = this.servidoresNaFaixa + 1;
                    }
                }
            })
        },
        resize(e) {
            e.target.style.height = 'auto'
            e.target.style.height = `${e.target.scrollHeight}px`
        },
        mudarBotaoSalvar(textoBotao) {
            let botaoSalvar = document.getElementById("botao-salvar");
            let conteudoBotao = document.getElementById("conteudo-botao");
            let spinnerLoading = document.getElementById("spinner-loading");

            let novoTextoConteudoBotao = "Carregando"

            //mudar para o carregando
            botaoSalvar.disabled = true;
            botaoSalvar.style.backgroundColor = "gray"
            conteudoBotao.innerHTML = novoTextoConteudoBotao;
            conteudoBotao.style.display = "inline-block"
            spinnerLoading.style.visibility = "visible"
            spinnerLoading.style.position = "relative"

            if (this.erro == true) {
                //mudar para o padrão (da aba dados básicos)
                botaoSalvar.disabled = false;
                botaoSalvar.style.backgroundColor = "#005516"
                conteudoBotao.innerHTML = textoBotao;
                spinnerLoading.style.visibility = "hidden"
                spinnerLoading.style.position = "absolute"
            }
        },
        maskString(value) {
            value = null;
            return value;
        },
        opcoesConfirmar() {
            let modalConfirmacao = document.getElementsByClassName('modal-confirmacao')

            if (modalConfirmacao[0].style.visibility !== "visible") {
                modalConfirmacao[0].style.visibility = "visible"
            } else {
                modalConfirmacao[0].style.visibility = "hidden"
                this.flagModalConfirmacao = false;
            }
        },
        confirmarEnvio() {
            this.flagModalConfirmacao = true;
            this.enviarForm();
        },
        async enviarForm() {
            let conteudoBotao = document.getElementById("conteudo-botao");
            const textoConteudoBotao = conteudoBotao.innerHTML;

            if (this.selectedOrgaoOrigem !== null) {
                this.cessaoForm.orgao_origem_id = this.selectedOrgaoOrigem.id
            }

            if (this.selectedOrgaoDestino !== null) {
                this.cessaoForm.orgao_destino_id = this.selectedOrgaoDestino.id
            }

            let flag = this.validarForm(this.cessaoForm);

            if (flag == 1) {
                this.erro = true;
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return false;
            }

            let flag2 = this.validarCessaoExiste(this.cessaoForm);

            this.validarTrocaStatus();

            if(this.flagModalConfirmacao == false) {
                if(flag2 == 1) {
                    this.opcoesConfirmar();
                    return false;
                }
            }

            try {
                this.carregando = true;
                this.erro = false;

                this.mudarBotaoSalvar(textoConteudoBotao);

                if(this.flagConvenio == false) {
                    this.cessaoForm.convenio_id = null;
                    this.cessaoForm.faixa_convenio_id = null;
                }

                if (this.renovar == true) {
                    this.cessaoForm.id = null;
                    this.cessaoForm.retorno = false;
                    this.cessaoForm.convenio_id = null;
                    this.cessaoForm.faixa_convenio_id = null;
                }

                if (this.cessaoForm.retorno == false) {
                    this.cessaoForm.data_retorno = null;
                    this.cessaoForm.numero_processo_sei_retorno = null;
                    this.cessaoForm.observacao_retorno = null;
                }

                if (this.cessaoApi.cancelTokens.setCessao) {
                    this.cessaoApi.cancelTokens.setCessao.cancel();
                }

                if (this.cessaoForm.termino_indeterminado == true) {
                    this.cessaoForm.data_termino = null;
                }

                //Se tiver no formulário de criação
                if(this.nomeRotaAtual !== "CessaoEdit") {
                    let data;
                    //Se tiver mais de 1 servidor
                    if (this.cessaoFormm.servidores_attributes.length > 1) {
                        data = null;
                        this.cessaoFormm.servidores_attributes.map(async (servidor) => {
                            if (servidor.servidorNovo == true) { //Se for cadastro de servidor novo
                                if (this.servidorApi.cancelTokens.setServidor) {
                                    this.servidorApi.cancelTokens.setServidor.cancel();
                                }

                                let dataServidor;
                                dataServidor = await this.servidorApi.setServidor(
                                    servidor
                                );
                                this.cessaoForm.servidor_id = dataServidor.id;

                                let dataCargo;
                                dataCargo = await this.cargoApi.setCargo(
                                    servidor.cargo
                                );
                                this.cessaoForm.cargo_id = dataCargo.id;

                                this.cargoServidor = {
                                    id: null,
                                    cargo_id: dataCargo.id,
                                    servidor_id: dataServidor.id
                                }
                                await this.cargoServidorApi.setCargoServidor(this.cargoServidor);
                            } else { //Se for um servidor já cadastrado
                                this.cessaoForm.servidor_id = servidor.id;
                                this.cessaoForm.cargo_id = servidor.cargo.id
                            }

                            data = await this.cessaoApi.setCessao(
                                this.cessaoForm
                            );
                        })

                        var rotaTabelaResultados = this.$router;
                        setTimeout(function () {
                            rotaTabelaResultados.push({
                                name: "Cessao",
                                params: {
                                    dados: data,
                                }
                            });
                        }, 1000);
                    } else { //Se tiver apenas 1 servidor
                        if (this.cessaoFormm.servidores_attributes[0].servidorNovo == true) { //Se for cadastro de servidor novo
                            if (this.servidorApi.cancelTokens.setServidor) {
                                this.servidorApi.cancelTokens.setServidor.cancel();
                            }

                            let dataServidor;
                            dataServidor = await this.servidorApi.setServidor(
                                this.cessaoFormm.servidores_attributes[0]
                            );
                            this.cessaoForm.servidor_id = dataServidor.id;

                            let dataCargo;
                            dataCargo = await this.cargoApi.setCargo(
                                this.cessaoFormm.servidores_attributes[0].cargo
                            );
                            this.cessaoForm.cargo_id = dataCargo.id;

                            this.cargoServidor = {
                                id: null,
                                cargo_id: dataCargo.id,
                                servidor_id: dataServidor.id
                            }
                            await this.cargoServidorApi.setCargoServidor(this.cargoServidor);
                        } else { //Se for um servidor já cadastrado
                            this.cessaoForm.servidor_id = this.cessaoFormm.servidores_attributes[0].id;
                            this.cessaoForm.cargo_id = this.cessaoFormm.servidores_attributes[0].cargo.id
                        }

                        let data;
                        data = await this.cessaoApi.setCessao(
                            this.cessaoForm
                        );

                        var rotaExibirCessao = this.$router;
                        setTimeout(function () {
                            rotaExibirCessao.push({
                                name: "CessaoShow",
                                params: {
                                    id: data.id,
                                },
                            });
                        }, 1000);
                    }
                } else { //Se tiver no formulário de edição
                    //Se o renovar for true (ou seja, se estiver renovando uma cessão), vai armazenar a cessão antiga pelo ID
                    //para depois mudar o status dela para encerrado, para que ela não apareça mais na home
                    if(this.renovar == true) {

                        const dadosCessaoAntiga = await this.cessaoApi.getCessao(
                            this.$route.params.id
                        );

                        this.cessaoAntiga = dadosCessaoAntiga

                        let idStatusEncerrado;
                        this.status.map((elemento) => {
                            if(elemento.codigo == 5) {
                                idStatusEncerrado = elemento.id
                            }
                        })

                        this.cessaoAntiga.status_id = idStatusEncerrado;

                        let dataAntiga;
                        dataAntiga = await this.cessaoApi.setCessao(
                            this.cessaoAntiga
                        );
                        console.log(dataAntiga)
                    }

                    let data;
                    data = await this.cessaoApi.setCessao(
                        this.cessaoForm
                    );

                    var rota = this.$router;
                    setTimeout(function () {
                        rota.push({
                            name: "CessaoShow",
                            params: {
                                id: data.id,
                            },
                        });
                    }, 1000);
                }

            } catch (err) {
                if (this.cessaoApi.isCancel(err)) {
                    return;
                }
                console.log(err);
                this.carregando = false;
                this.erro = true;

                this.mudarBotaoSalvar(textoConteudoBotao)

                const { response } = err;
                if (response && response.data) {
                    this.msgErros.default = response.data;
                } else {
                    this.msgErros.default =
                        "Houve um erro ao salvar o registro. Por favor, recarregue a página e tente novamente.";
                }
            }
        },
        zerarMensagens() {
            this.msgErros.servidor = ''
            this.msgErros.servidor_cpf = ''
            this.msgErros.servidor_nome = ''
            this.msgErros.servidor_cargo = ''
            this.msgErros.orgao = ''
            this.msgErros.orgao_origem = ''
            this.msgErros.orgao_destino = ''
            this.msgErros.termino_indeterminado = ''
            this.msgErros.data = ''
            //this.msgErros.data_inicio = ''
            //this.msgErros.data_termino = ''
            //this.msgErros.data_entrada_processo = ''
            this.msgErros.onus = ''
            this.msgErros.status = ''
            this.msgErros.data_retorno = ''
            this.msgErros.numero_processo_sei_retorno = ''
            this.msgErros.convenio = ''
            this.msgErros.faixa_convenio = ''
            this.msgErros.numero_processo_sei = ''
            this.msgErros.default = ''
        },
        formatarDataParaComparacao(value) {
            let newValue = new Date(value);
            let yearToCompare = newValue.getFullYear();
            return yearToCompare;
        },
        validarCessaoExiste(cessaoForm) {
            let flag2 = 0;

            //var nomeRotaAtual = this.$router.currentRoute.name;

            //Validar cessão para checar se já tem outra existente com o mesmo servidor, órgão de origem, órgão de destino, data de inicio e fim
            //se tiver, exibir mensagem de confirmação.

            this.cessaoFormm.servidores_attributes.map((servidor) => {
                this.cessoesData.map((cessao) => {

                    let dataInicioCadastrada = this.formatarDataParaComparacao(cessao.data_inicio)
                    let dataFimCadastrada = this.formatarDataParaComparacao(cessao.data_termino)

                    let dataInicioFormulario = this.formatarDataParaComparacao(cessaoForm.data_inicio)
                    let dataFimFormulario = this.formatarDataParaComparacao(cessaoForm.data_termino)

                    if(cessao.servidor_id == servidor.id &&
                        cessao.orgao_origem_id == this.selectedOrgaoOrigem.id &&
                        cessao.orgao_destino_id == this.selectedOrgaoDestino.id &&
                        dataInicioCadastrada == dataInicioFormulario &&
                        dataFimCadastrada == dataFimFormulario){

                        flag2 = 1;
                        return;
                    }

                    let idStatusVigente;
                    let idStatusAndamento;

                    this.status.map((elemento) => {
                        if(elemento.codigo == 1) {
                            idStatusAndamento = elemento.id
                        }
                        if(elemento.codigo == 2) {
                            idStatusVigente = elemento.id
                        }
                    })

                    if(cessao.servidor_id == servidor.id &&
                        cessao.orgao_origem_id == this.selectedOrgaoOrigem.id &&
                        cessao.orgao_destino_id != this.selectedOrgaoDestino.id &&
                        (cessao.status_id == idStatusAndamento || cessao.status_id == idStatusVigente)
                        ){

                        flag2 = 1;
                        return;
                    }
                });
            })

            return flag2;
        },
        validarTrocaStatus() {
        //Validar cessão para checar se já tem outra existente com o mesmo servidor, se tiver,
        //pegar as cessões existentes desse servidor, que estiverem com status VIGENTE e ORGÃO DE ORIGEM iguais, e alterar os status para encerrado.
        //Isso tudo só vai acontecer se o status da cessão atual (criada ou editada) for mudado ou colocado como vigente.

            let idStatusVigente;
            this.status.map((elemento) => {
                if(elemento.codigo == 2) {
                    idStatusVigente = elemento.id
                }
            })

            if(this.cessaoForm.status_id === idStatusVigente) {
                let idStatusEncerrado;
                this.status.map((elemento) => {
                    if(elemento.codigo == 5) {
                        idStatusEncerrado = elemento.id
                    }
                })

                if(this.nomeRotaAtual == 'CessaoNew') {
                    this.cessaoFormm.servidores_attributes.map((servidor) => {
                        this.cessoesData.map(async (cessaoExistente) => {
                            if(cessaoExistente.servidor_id == servidor.id &&
                            cessaoExistente.status_id == idStatusVigente &&
                            cessaoExistente.orgao_origem_id == this.cessaoForm.orgao_origem_id &&
                            this.cessaoForm.id !== cessaoExistente.id) {
                                cessaoExistente.status_id = idStatusEncerrado;
                                await this.cessaoApi.setCessao(
                                    cessaoExistente
                                );
                            }
                        });
                    })
                } else {
                    this.cessoesData.map(async (cessaoExistente) => {
                        if(cessaoExistente.servidor_id == this.cessaoForm.servidor_id &&
                            cessaoExistente.status_id == idStatusVigente &&
                            cessaoExistente.orgao_origem_id == this.cessaoForm.orgao_origem_id &&
                            this.cessaoForm.id !== cessaoExistente.id) {
                                cessaoExistente.status_id = idStatusEncerrado;
                                await this.cessaoApi.setCessao(
                                    cessaoExistente
                                );
                        }
                    });
                }

            }
        },
        validarForm(cessaoForm) {
            let flag = 0;

            this.zerarMensagens();

            var nomeRotaAtual = this.$router.currentRoute.name;

            if(nomeRotaAtual !== "CessaoEdit") {
                if (cessaoForm.convenio_id !== null && cessaoForm.faixa_convenio_id !== null) {
                    if ((this.limiteFaixa - this.servidoresNaFaixa) <= 0) {
                        this.msgErros.default = 'A faixa do convênio não possui mais limite.'
                        flag = 1;
                    }
                }
            }

            //Órgão de origem não pode ser igual ao órgão de destino
            if (cessaoForm.orgao_destino_id !== null && cessaoForm.orgao_origem_id !== null) {
                if (cessaoForm.orgao_origem_id == cessaoForm.orgao_destino_id) {
                    this.msgErros.orgao = "O órgão de origem não pode ser igual ao órgão de destino.";
                    flag = 1;
                }
            }

            if (cessaoForm.termino_indeterminado !== null && cessaoForm.termino_indeterminado !== true) {
                if(this.cessaoForm.data_inicio !== null && this.cessaoForm.data_termino !== null) {
                    //Data de inicio da cessão deve ser menor que Data de término da cessão
                    const dataInicio = new Date(this.cessaoForm.data_inicio)
                    const dataTermino = new Date(this.cessaoForm.data_termino)

                    // Comparar as datas
                    if (dataInicio >= dataTermino) {
                        this.msgErros.cessao.data = "A data de início da cessão não pode ser maior ou igual a Data de término da cessão.";
                        flag = 1;
                    }
                }
            }

            //O sistema não deve permitir o cadastro de cessão com datas conflitantes.
            //  Neste cenário, o sistema deverá informar o usuário da cessão já existente, onde o mesmo deverá editar o cadastro da cessão alterando a data
            //  para não conflitar com a nova cessão(observação será obrigatória);
            if(nomeRotaAtual !== "CessaoEdit") {
                this.cessoesData.map((cessao) => {
                    if (cessao.servidor_id == this.cessaoForm.servidor_id) { //Compara se o servidor passado na cessão tem algum registro de cessão no banco

                        //Converte para um objeto Date, as datas de início e término do servidor cadastrado
                        const dataInicioCadastrada = new Date(cessao.data_inicio)
                        const dataTerminoCadastrada = new Date(cessao.data_termino)

                        //Converte para um objeto Date, as datas de início e término passadas no formulário de criação de cessão
                        const dataInicioFormulario = new Date(this.cessaoForm.data_inicio)
                        const dataTerminoFormulario = new Date(this.cessaoForm.data_termino)

                        if (dataTerminoFormulario >= dataInicioCadastrada && dataInicioFormulario <= dataTerminoCadastrada) {
                            this.msgErros.default = "Usuário já está em uma cessão! Por favor, edite as datas da cessão existente, para que não haja datas conflitantes. Será necessário colocar uma observação na cessão editada!";
                            flag = 1;
                        }

                        if (dataInicioFormulario <= dataTerminoCadastrada && dataTerminoFormulario >= dataInicioCadastrada) {
                            this.msgErros.default = "Usuário já está em uma cessão! Por favor, edite as datas da cessão existente, para que não haja datas conflitantes. Será necessário colocar uma observação na cessão editada!";
                            flag = 1;
                        }
                    }
                })
            }

            if(this.nomeRotaAtual !== "CessaoEdit") {
                //Campos em branco

                this.cessaoFormm.servidores_attributes.map((servidor) => {
                    if (servidor.cpf == null || servidor.cpf == '') {
                        this.msgErros.servidor_cpf = "O CPF do campo servidor não foi preenchido.";
                        flag = 1;
                        return;
                    }

                    //CPF tem que ser válido
                    if(!this.validarCPF(servidor.cpf)){
                        this.msgErros.servidor_cpf = "CPF inválido.";
                        flag = 1;
                    }

                    if (servidor.nome == null || servidor.nome == '') {
                        this.msgErros.servidor_nome = "O nome do campo servidor não foi preenchido.";
                        flag = 1;
                        return;
                    }

                    if (servidor.cargo == null || servidor.cargo == '' || servidor.cargo.length == 0) {
                        this.msgErros.servidor_cargo = "O cargo do campo servidor não foi preenchido.";
                        flag = 1;
                        return;
                    }
                })
            }

            if (this.selectedOrgaoOrigem == null || this.selectedOrgaoOrigem == '') {
                this.msgErros.orgao_origem = "O campo órgão de origem não foi preenchido.";
                flag = 1;
            }

            if (this.selectedOrgaoDestino == null || this.selectedOrgaoDestino == '') {
                this.msgErros.orgao_destino = "O campo órgão de destino não foi preenchido.";
                flag = 1;
            }

            if (cessaoForm.termino_indeterminado == null) {
                this.msgErros.termino_indeterminado = "O campo tempo indeterminado não foi preenchido.";
                flag = 1;
            }

            /*if (cessaoForm.data_inicio == null || cessaoForm.data_inicio == '') {
                this.msgErros.data_inicio = "O campo data de inicio da cessão não foi preenchido.";
                flag = 1;
            }

            if (cessaoForm.termino_indeterminado !== true) {
                if (cessaoForm.data_termino == null || cessaoForm.data_termino == '') {
                    this.msgErros.data_termino = "O campo data de término da cessão não foi preenchido.";
                    flag = 1;
                }
            }*/

            if (cessaoForm.onus_id == null || cessaoForm.onus_id == '') {
                this.msgErros.onus = "O campo ônus não foi preenchido.";
                flag = 1;
            }

            if (cessaoForm.status_id == null || cessaoForm.status_id == '') {
                this.msgErros.status = "O campo status não foi preenchido.";
                flag = 1;
            }

            if(nomeRotaAtual == "CessaoEdit" && this.renovar == false) {
                if(this.cessaoForm.retorno == true) {
                    if(this.cessaoForm.data_retorno == null || this.cessaoForm.data_retorno == '') {
                        this.msgErros.data_retorno = "O campo data de retorno não foi preenchido.";
                        flag = 1;
                    }
                    if (this.cessaoForm.numero_processo_sei_retorno == null || this.cessaoForm.numero_processo_sei_retorno == '') {
                        this.msgErros.numero_processo_sei_retorno = "O campo número do processo SEI do retorno não foi preenchido.";
                        flag = 1;
                    }
                }
            }

            if(this.flagConvenio == true) {
                if (cessaoForm.convenio_id == null || cessaoForm.convenio_id == '') {
                    this.msgErros.convenio = "O campo convênio não foi preenchido.";
                    flag = 1;
                }
            }

            if (this.flagFaixa == true) {
                if (cessaoForm.faixa_convenio_id == null || cessaoForm.faixa_convenio_id == '') {
                    this.msgErros.faixa_convenio = "O campo faixa do convênio não foi preenchido.";
                    flag = 1;
                }
            }

            /*if (cessaoForm.data_entrada_processo == null || cessaoForm.data_entrada_processo == '') {
                this.msgErros.data_entrada_processo = "O campo data de entrada do processo não foi preenchido.";
                flag = 1;
            }*/

            if (cessaoForm.numero_processo_sei == null || cessaoForm.numero_processo_sei == '') {
                this.msgErros.numero_processo_sei = "O campo número do processo SEI não foi preenchido.";
                flag = 1;
            }

            return flag;
        },
        validarCPF(cpf) {
            cpf = cpf.replace(/\D/g, '');
            if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
                return false;
            }

            let soma = 0;
            let resto;

            for (let i = 1; i <= 9; i++) {
                soma += parseInt(cpf.charAt(i - 1)) * (11 - i);
            }
            resto = (soma * 10) % 11;

            if (resto === 10 || resto === 11) resto = 0;
            if (resto !== parseInt(cpf.charAt(9))) {
                return false;
            }

            soma = 0;
            for (let i = 1; i <= 10; i++) {
                soma += parseInt(cpf.charAt(i - 1)) * (12 - i);
            }
            resto = (soma * 10) % 11;

            if (resto === 10 || resto === 11) resto = 0;
            if (resto !== parseInt(cpf.charAt(10))) {
                return false;
            }

            return true;
        },
        possuiAsterisco(texto) {
            var texto_final = "* " + texto
            return texto_final
        }
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
div.item.radio {
  display: grid;
  gap: 0rem;
  align-items: center;
  width: fit-content;
  cursor: default;
}

.radioContainer {
  display: flex;
  gap: 1rem;

  .radioTrue {
    font-size: 1rem;
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  .radioFalse {
    font-size: 1rem;
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }
}

::v-deep .v-select {
  .vs__dropdown-toggle {
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .vs__selected {
    font-size: 0.9rem;
    color: #333;
  }

  .vs__search {
    font-size: 14px;
    border: none;
  }

  .vs__dropdown-menu {
    border: 1px solid #ccc;
    border-radius: 4px;
    position: static;
    font-size: 0.9rem;
  }

  .vs__dropdown-option {
    padding: 5px 12px;
  }

  .vs__dropdown-option--highlight {
    background-color: #007bff;
    color: #fff;
  }
}

@media screen and (min-width: "1066px") {
  .modal-confirmacao {
    visibility: hidden;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: table;
    transition: opacity 0.3s ease;

    .modal-mask-confirmacao {
      border-radius: 1rem;
      position: relative;
      z-index: 1000;
      top: 20vh;
      width: calc(70vw - 10rem);
      height: fit-content;
      background-color: #f2f2f2;
      transition: opacity 0.3s ease;
      margin: 0 auto;
    }
    .modal-container-confirmacao {
      height: 100%;
      padding: 1rem;
      position: relative;
      overflow-y: auto;
      overflow-x: auto;
      font-family: "Montserrat-Medium";

      .modal-header-confirmacao {
        height: fit-content;
        width: fit-content;
        margin: 0.5rem auto 2rem auto;
        display: grid;
        gap: 1rem;

        h2 {
          font-size: 1.2rem;
          font-weight: bold;
        }

        h3 {
          font-size: 1.2rem;
        }
      }

      .modal-footer-confirmacao {
        margin: 0.7rem auto 0rem auto;
        place-content: space-around;
        font-size: 1rem;
        display: flex;

        .btn-confirmar-confirmacao {
          border: none;
          border-radius: 0.5rem;
          width: 30%;
          height: 2.2rem;
          background-color: #005516;
          color: #f2f2f2;
        }

        .btn-cancelar-confirmacao {
          border: none;
          border-radius: 0.5rem;
          width: 30%;
          height: 2.2rem;
          background-color: #bd0000;
          color: #f2f2f2;
        }
      }
    }
  }

  .observacaoConvenio {
    margin: 0.5rem 0 0 0;
    p {
      font-size: 1rem;
      font-weight: bold;
      color: red;
    }
    span {
      font-size: 0.9rem;
      color: red;
    }
  }

  .caixa-form {
    .gridbox-1 {
      display: grid;
      width: -webkit-fill-available;
    }

    .flexbox-2-button {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }

    .flexbox-3 {
      display: grid;
      grid-template: "a b c";
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1.5rem;
      align-items: start;
    }

    .servidorForm {
      padding: 0 2rem;
    }

    #divNomeServidor {
      visibility: hidden;
      height: fit-content;
    }

    .servidorButton {
      display: inline-block;
      border: none;
      background-color: transparent;
      color: #00a339;
      font-weight: bold;
      font-size: 0.8rem;
      margin-left: 0.5rem;
      cursor: pointer;
    }

    .servidorButton:hover {
      text-decoration: underline;
    }

    .servidorButtonDisabled {
      border: none;
      background-color: transparent;
      color: gray;
      font-weight: normal;
      font-size: 0.8rem;
      margin-left: 0.5rem;
      cursor: not-allowed;
    }

    .item {
      display: grid;
      margin: 0.5rem 0;
      width: -webkit-fill-available;
      height: fit-content;

      span {
        width: fit-content;
        display: inline-block;
      }
    }
  }
}

@media screen and (min-width: "60px") and (max-width: "1065px") {
  .modal-confirmacao {
    visibility: hidden;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: table;
    transition: opacity 0.3s ease;

    .modal-mask-confirmacao {
      border-radius: 1rem;
      position: relative;
      z-index: 1000;
      top: 20vh;
      width: calc(70vw - 10rem);
      height: fit-content;
      background-color: #f2f2f2;
      transition: opacity 0.3s ease;
      margin: 0 auto;
    }
    .modal-container-confirmacao {
      height: 100%;
      padding: 1rem;
      position: relative;
      overflow-y: auto;
      overflow-x: auto;
      font-family: "Montserrat-Medium";

      .modal-header-confirmacao {
        height: fit-content;
        width: fit-content;
        margin: 0.5rem auto 2rem auto;
        display: grid;
        gap: 1rem;

        h2 {
          font-size: 1.2rem;
          font-weight: bold;
        }

        h3 {
          font-size: 1.2rem;
        }
      }

      .modal-footer-confirmacao {
        margin: 0.7rem auto 0rem auto;
        place-content: space-around;
        font-size: 1rem;
        display: flex;

        .btn-confirmar-confirmacao {
          border: none;
          border-radius: 0.5rem;
          width: 30%;
          height: 2.2rem;
          background-color: #005516;
          color: #f2f2f2;
        }

        .btn-cancelar-confirmacao {
          border: none;
          border-radius: 0.5rem;
          width: 30%;
          height: 2.2rem;
          background-color: #bd0000;
          color: #f2f2f2;
        }
      }
    }
  }

  .observacaoConvenio {
    margin: 0.5rem 0 0 0;
    p {
      font-size: 1rem;
      font-weight: bold;
      color: red;
    }
    span {
      font-size: 0.9rem;
      color: red;
    }
  }

  .caixa-form {
    .gridbox-1 {
      display: grid;
      width: -webkit-fill-available;
    }

    .servidorForm {
      padding: 0 2rem;
    }

    #divNomeServidor {
      visibility: hidden;
      height: fit-content;
    }

    .servidorButton {
      display: inline-block;
      border: none;
      background-color: transparent;
      color: #00a339;
      font-weight: bold;
      font-size: 0.8rem;
      margin-left: 0.5rem;
      cursor: pointer;
    }

    .servidorButton:hover {
      text-decoration: underline;
    }

    .servidorButtonDisabled {
      border: none;
      background-color: transparent;
      color: gray;
      font-weight: normal;
      font-size: 0.8rem;
      margin-left: 0.5rem;
      cursor: not-allowed;
    }

    .item {
      .flexbox-2 {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
      }

      .flexbox-2-button {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
      }

      span {
        width: fit-content;
        display: inline-block;

        .servidorForm {
          box-shadow: #000 3px 5px 15px;
          border-radius: 2rem;
          padding: 1rem;
        }

        .servidorButton {
          display: inline-block;
          border: none;
          background-color: transparent;
          color: #00a339;
          font-weight: bold;
          font-size: 0.8rem;
          margin-left: 0.5rem;
          cursor: pointer;
        }

        .servidorButton:hover {
          text-decoration: underline;
        }

        .servidorButtonDisabled {
          border: none;
          background-color: transparent;
          color: gray;
          font-weight: normal;
          font-size: 0.8rem;
          margin-left: 0.5rem;
          cursor: not-allowed;
        }
      }
    }
  }
}

@media screen and (max-width: "700px") {
  .modal-confirmacao {
    visibility: hidden;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: table;
    transition: opacity 0.3s ease;

    .modal-mask-confirmacao {
      border-radius: 1rem;
      position: relative;
      z-index: 1000;
      top: 20vh;
      width: calc(70vw - 10rem);
      height: fit-content;
      background-color: #f2f2f2;
      transition: opacity 0.3s ease;
      margin: 0 auto;
    }
    .modal-container-confirmacao {
      height: 100%;
      padding: 1rem;
      position: relative;
      overflow-y: auto;
      overflow-x: auto;
      font-family: "Montserrat-Medium";

      .modal-header-confirmacao {
        height: fit-content;
        width: fit-content;
        margin: 0.5rem auto 2rem auto;
        display: grid;
        gap: 1rem;

        h2 {
          font-size: 1.2rem;
          font-weight: bold;
        }

        h3 {
          font-size: 1.2rem;
        }
      }

      .modal-footer-confirmacao {
        margin: 0.7rem auto 0rem auto;
        place-content: space-around;
        font-size: 1rem;
        display: flex;

        .btn-confirmar-confirmacao {
          border: none;
          border-radius: 0.5rem;
          width: 30%;
          height: 2.2rem;
          background-color: #005516;
          color: #f2f2f2;
        }

        .btn-cancelar-confirmacao {
          border: none;
          border-radius: 0.5rem;
          width: 30%;
          height: 2.2rem;
          background-color: #bd0000;
          color: #f2f2f2;
        }
      }
    }
  }
  .caixa-form {
    .item {
      .flexbox-2 {
        display: grid;
        justify-content: normal;
        gap: 0rem;
      }

      .flexbox-2-button {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
      }
    }
  }
}
</style>
