<template>
  <div>
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img
              src="../../assets/imagens/loadingSpinner.gif"
              alt="Roda de carregamento girando"
            />
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="topo-header centro">
        <botao-voltar class="voltar" nomeRota="Cessao" />
        <h1>Visualizar Cessão</h1>
      </div>
      <div class="form centro">
        <div class="dados">
          <fieldset>
            <div class="dados">
              <div class="flexbox-1">
                <p>
                  Nome do(a) servidor(a):
                  <span>{{ cessao.nome_servidor }}</span>
                </p>
                <p>
                  CPF do(a) servidor(a):
                  <span>{{ formatarCPF(cessao.cpf_servidor) }}</span>
                </p>
              </div>
              <p>
                Cargo do(a) servidor(a):
                <span v-if="cessao.nome_cargo">{{ cessao.nome_cargo }}</span>
                <span v-else>Nenhum cargo encontrado</span>
              </p>
            </div>
          </fieldset>
          <fieldset>
            <div class="dados">
              <div class="flexbox-1">
                <p>
                  Ônus:
                  <span>{{ cessao.onus }}</span>
                </p>
                <p>
                  Status:
                  <span>{{ cessao.status }}</span>
                </p>
              </div>
              <div class="flexbox-1">
                <p>
                  Órgão de origem:
                  <span>{{ cessao.orgao_origem }}</span>
                </p>
                <p>
                  Órgão de destino:
                  <span>{{ cessao.orgao_destino }}</span>
                </p>
              </div>
              <div class="flexbox-1">
                <p>
                  Data de início da cessão:
                  <span>{{ formatarData(cessao.data_inicio) }}</span>
                </p>
                <p v-if="cessao.data_termino !== null">
                  Data de término da cessão:
                  <span>{{ formatarData(cessao.data_termino) }}</span>
                </p>
                <p v-else>
                  Data de término da cessão:
                  <span>Indeterminada</span>
                </p>
              </div>
              <div class="flexbox-1">
                <!--
                <p>Data de entrada do processo:
                  <span>{{ formatarData(cessao.data_entrada_processo) }}</span>
                </p>-->
                <p>
                  Cargo ou FC:
                  <span v-if="cessao.fc">{{ cessao.fc }}</span>
                  <span v-else>Nenhum</span>
                </p>
                <p v-if="cessao.renovar !== null">
                  Renovação:
                  <span>{{ cessao.renovar ? "Sim" : "Não" }}</span>
                </p>
              </div>
              <div v-if="cessao.retorno == true">
                <p>Retorno:</p>
                <fieldset class="convenio">
                  <div class="flexbox-3">
                    <p>
                      Data de retorno:
                      <span>{{ formatarData(cessao.data_retorno) }}</span>
                    </p>
                    <p>
                      Processo SEI do retorno:
                      <span>{{ cessao.numero_processo_sei_retorno }}</span>
                    </p>
                  </div>
                  <p
                    v-if="
                      cessao.observacao_retorno !== null &&
                        cessao.observacao_retorno !== ''
                    "
                  >
                    Observação do retorno:
                    <span>{{ cessao.observacao_retorno }}</span>
                  </p>
                </fieldset>
              </div>
              <div class="flexbox-1">
                <p>
                  Processo SEI:
                  <a
                    :href="retornarLinkAcesso(cessao.link_acesso)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ cessao.numero_processo_sei }}
                  </a>
                </p>
              </div>
              <div class="andamentos-sei">
                <p>Andamentos no SEI:</p>
                <table class="tabela-andamentos">
                  <thead>
                    <tr>
                      <th>Data/Hora</th>
                      <th>Unidade</th>
                      <th>Usuário</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="andamentoSei in andamentosPaginados"
                      :key="andamentoSei.id_andamento"
                      class="fieldset-andamentos"
                    >
                      <td data-label="Data/Hora:">
                        <span>{{ andamentoSei.data_hora }}</span>
                      </td>
                      <td data-label="Unidade:">
                        <span>{{ andamentoSei.unidade.sigla }}</span>
                      </td>
                      <td data-label="Usuário:">
                        <span>{{ andamentoSei.usuario.sigla }}</span>
                      </td>
                      <td data-label="Descrição:">
                        <span>{{
                          formatarDescricao(andamentoSei.descricao)
                        }}</span>
                        <!--FORMATAR DESCRIÇÃO-->
                      </td>
                    </tr>
                    <tr
                      v-if="andamentosSei.length === 0"
                      class="sem-resultados"
                    >
                      <td colspan="4"><b>Nenhum Resultado Encontrado</b></td>
                    </tr>
                  </tbody>
                </table>
                <paginate
                  :page-count="totalPaginas"
                  :click-handler="paginarAndamentos"
                  :prev-text="'Anterior'"
                  :next-text="'Próximo'"
                  :container-class="'className'"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :disabled-class="'disabled'"
                  :active-class="'active-page'"
                >
                </paginate>
              </div>
              <div v-if="cessao.convenio_id !== null">
                <p>Convênio:</p>
                <fieldset class="convenio">
                  <div v-if="cessao.nome_convenio !== null">
                    <p>Nome do cônvenio:</p>
                    <span>{{ cessao.nome_convenio }}</span>
                  </div>
                  <div class="dados">
                    <p>
                      Nome da faixa:
                      <span v-if="cessao.faixa_convenio">{{
                        cessao.faixa_convenio
                      }}</span>
                      <span v-else>Sem faixa</span>
                    </p>
                  </div>
                </fieldset>
              </div>
              <div
                v-if="cessao.observacao !== null && cessao.observacao !== ''"
              >
                <p>
                  Observação:
                  <span>{{ cessao.observacao }}</span>
                </p>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="bt-editar" v-if="temPermissao.admin || temPermissao.gpd">
          <div class="div-center">
            <div :id="cessao.id">
              <a :href="'/cessoes/' + cessao.id + '/edit'">
                <button id="botao-editar">
                  Editar
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer
          :createdByLabel="'Criado por'"
          :criadoPor="cessao.criado_por"
          :lastUpdatedByLabel="'Última atualização feita por'"
          :atualizadoPor="cessao.atualizado_por"
          :emLabel="' em '"
          :SistemaLabel="'Sistema'"
          :created_at="cessao.created_at"
          :updated_at="cessao.updated_at"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BotaoVoltar from "../BotaoVoltar.vue";
import CessaoApi from "../../services/CessaoApi";
import { parseISO, format } from "date-fns";
import Footer from "../Footer.vue";
import Paginate from "vuejs-paginate";

export default {
  name: "ExibirCessao",
  props: {
    cessao: Object,
    temPermissao: Object,
  },
  components: {
    BotaoVoltar,
    Footer,
    Paginate,
  },
  data() {
    return {
      carregando: true,
      r: false,
      andamentosSei: [],
      idProcedimento: null,
      dadosCessao: {
        criado_por: null,
        atualizado_por: null,
        created_at: null,
        updated_at: null,
      },
      paginaAtual: 1,
      resultadosPorPagina: 5,
    };
  },
  computed: {
    totalPaginas() {
      return Math.ceil(this.andamentosSei.length / this.resultadosPorPagina);
    },
    andamentosPaginados() {
      const inicio = (this.paginaAtual - 1) * this.resultadosPorPagina;
      const fim = inicio + this.resultadosPorPagina;
      return this.andamentosSei.slice(inicio, fim);
    },
  },
  created() {
    this.cessaoApi = new CessaoApi();
    this.consultarProcedimentoSei();
    this.consultarAndamentosSei();
  },
  methods: {
    retornarLinkAcesso(urlSei) {
      return urlSei + this.idProcedimento;
    },
    async consultarProcedimentoSei() {
      try {
        this.carregando = true;

        let numeroProcessoSei = this.cessao.numero_processo_sei;
        const procedimentoSei = await this.cessaoApi.getProcedimentoSei(
          numeroProcessoSei
        );
        let id_procedimento =
          procedimentoSei.consultar_procedimento_response.parametros
            .id_procedimento;

        if (id_procedimento) {
          this.idProcedimento = id_procedimento;
        } else {
          this.idProcedimento = null;
        }

        this.carregando = false;
      } catch (err) {
        console.log("consultarProcedimentoSei", err);

        this.carregando = false;
      }
    },
    async consultarAndamentosSei() {
      try {
        this.carregando = true;

        let numeroProcessoSei = this.cessao.numero_processo_sei;
        const dadosAndamentosSei = await this.cessaoApi.getAndamentosSei(
          numeroProcessoSei
        );
        let arrayAndamentosSei = dadosAndamentosSei;

        arrayAndamentosSei.listar_andamentos_response.parametros.item.map(
          (andamentoSei) => {
            this.andamentosSei.push(andamentoSei);
          }
        );

        this.carregando = false;
      } catch (err) {
        console.log("consultarAndamentosSei", err);

        this.carregando = false;
      }
    },
    formatarDataHora: function(value) {
      return (
        format(parseISO(value), "dd/MM/yyyy") +
        " às " +
        format(parseISO(value), "HH:mm:ss.")
      );
    },
    formatarData: function(value) {
      if (value == null) {
        return;
      }
      return format(parseISO(value), "dd/MM/yyyy");
    },
    formatarCPF(cpf) {
      // Lógica para formatar o CPF (exemplo: 123.456.789-01)
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    formatarDescricao(descricao) {
      let stringOriginal = descricao;

      // Verificar se a string contém a tag <a>
      if (stringOriginal.includes("<a")) {
        // Criar um elemento temporário
        let elementoTemporario = document.createElement("div");
        elementoTemporario.innerHTML = stringOriginal;

        // Extrair o texto da tag <a>
        let textoDentroDaTag = elementoTemporario.querySelector("a")
          .textContent;

        // Substituir a tag <a> pela string extraída
        let novaString = stringOriginal.replace(
          /<a.*?>(.*?)<\/a>/,
          textoDentroDaTag
        );

        return novaString;
      } else {
        // Se a string não contém a tag <a>, mantê-la inalterada
        return stringOriginal;
      }
    },
    paginarAndamentos(numeroPagina) {
      this.paginaAtual = numeroPagina;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-page {
  color: #484344;

  a {
    cursor: not-allowed;
  }
}
</style>
