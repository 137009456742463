<template>
    <div class="wrapper">
        <div class="topo-header centro">
            <botao-voltar nomeRota="Orgao" />
            <h1>{{ orgaoForm.id ? "Editar" : "Novo" }} Órgão</h1>
        </div>
        <div class="tab-dados-basicos">
            <div class="form centro">
                <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro"/>
                <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>                
                <form @submit.prevent="enviarForm">
                    <div class="caixa-form item">
                        <div class="flexbox-1">
                            <div class="item">
                                <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                                <input type="text" placeholder="Digite o nome" v-model="orgaoForm.nome" />
                            </div>
                            <div class="item">
                                <label><b>Sigla:</b></label>
                                <input type="text" placeholder="Digite a sigla" v-model="orgaoForm.sigla" />
                            </div>
                        </div> 
                        <div class="flexbox-3">
                            {{ this.setarOptions() }}
                            <div class="item">
                                <label for="selectEsfera"><b>{{ possuiAsterisco("Esfera:") }}</b></label>
                                <select v-model="orgaoForm.esfera">
                                    <option :value="orgaoForm.esfera" disabled selected hidden>{{ orgaoForm.esfera }}</option>
                                    <option
                                        v-for="esfera in optionsEsferas"
                                        :key="esfera"
                                        :value="esfera"
                                    >
                                        {{ esfera }}
                                    </option>
                                </select>
                            </div>
                            <div class="item">
                                <label for="selectPoder"><b>{{ possuiAsterisco("Poder:") }}</b></label>
                                <select v-model="orgaoForm.poder">
                                    <option :value="orgaoForm.poder" disabled selected hidden>{{ orgaoForm.poder }}</option>
                                    <option
                                        v-for="poder in optionsPoderes"
                                        :key="poder"
                                        :value="poder"
                                    >
                                        {{ poder }}
                                    </option>
                                </select>
                            </div>
                            <div class="item">
                                <label for="selectUf"><b>{{ possuiAsterisco("UF:") }}</b></label>
                                <v-select
                                    class="vselect"
                                    ref="vselect" 
                                    v-model="orgaoForm.ufs_id" 
                                    id="selectUf" 
                                    :options="ufs"
                                    :reduce="uf => uf.id" 
                                    label="uf" 
                                    placeholder="Todos">
                                </v-select>
                            </div>
                        </div>
                        <div class="bt-salvar">
                            <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                                <img id="spinner-loading" class="spinner-loading"
                                    src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                <p id="conteudo-botao">{{ orgaoForm.id ? "Atualizar" : "Criar" }}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import OrgaoApi from "../../services/OrgaoApi";
    import BotaoVoltar from "../../components/BotaoVoltar.vue";
    import MsgErroFormularios from "../MsgErroFormularios";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: "OrgaoForm",
        props: {
            orgao: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                count: -1,
                count1: null,
                filess: null,
                carregando: true,
                erro: false,
                msgErros: {
                    nome: '',
                    sigla: '',
                    esfera: '',
                    poder: '',
                    ufs_id:'',
                    default: '',
                },
                esferas: [],
                poderes: [],
                ufs: [],
                optionsEsferas: [],
                optionsPoderes: [],
                orgaoForm: this.orgao
                    ? {
                        id: this.orgao.id,
                        nome: this.orgao.nome,
                        sigla: this.orgao.sigla,
                        esfera: this.orgao.esfera,
                        poder: this.orgao.poder,
                        ufs_id: this.orgao.ufs_id,
                    }
                    : {
                        id: null,
                        nome: null,
                        sigla: null,
                        esfera: null,
                        poder: null,
                        ufs_id: null,
                    },
            };
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
            vSelect,
        },
        created() {
            this.orgaoApi = new OrgaoApi();
            this.carregarEsferas();
            this.carregarPoderes();
            this.carregarUfs();
        },
        methods: {
            fecharErro() {
                this.erro = false;
            },
            async carregarEsferas() {
                try {
                    this.carregando = true;
                    this.erro = false;

                    const dadosEsferas = await this.orgaoApi.getEsferas();
                    this.esferas = dadosEsferas
                } catch (err) {
                    console.log("carregarEsferas", err);

                    this.carregando = false;
                    this.erro = true;
                }
            },
            async carregarPoderes() {
                try {
                    this.carregando = true;
                    this.erro = false;

                    const dadosPoderes = await this.orgaoApi.getPoderes();
                    this.poderes = dadosPoderes
                } catch (err) {
                    console.log("carregarPoderes", err);

                    this.carregando = false;
                    this.erro = true;
                }
            },
            async carregarUfs() {
                try {
                    this.carregando = true;
                    this.erro = false;

                    const dadosUfs = await this.orgaoApi.getUfs();
                    this.ufs = dadosUfs
                } catch (err) {
                    console.log("carregarUfs", err);

                    this.carregando = false;
                    this.erro = true;
                }
            },
            setarOptions() {
                const POSICAO_ZERO = 0;
                for (let i = 0; i < this.esferas.length; i++) {
                    this.optionsEsferas[i] = this.esferas[i][POSICAO_ZERO];
                }
                for (let i = 0; i < this.poderes.length; i++) {
                    this.optionsPoderes[i] = this.poderes[i][POSICAO_ZERO];
                }
            },
            retornarIdEsfera(esferas) {
                const POSICAO_ZERO = 0;
                var esferaOrgao1 = this.orgaoForm.esfera;

                for (var i = 0; i < esferas.length; i++) {
                    if (esferas[i][POSICAO_ZERO] == esferaOrgao1) {
                        var id = i;
                    }
                }

                return id
            },
            retornarIdPoder(poderes) {
                const POSICAO_ZERO = 0;
                var poderOrgao1 = this.orgaoForm.poder;

                for (var i = 0; i < poderes.length; i++) {
                    if (poderes[i][POSICAO_ZERO] == poderOrgao1) {
                        var id = i;
                    }
                }

                return id
            },
            mudarBotaoSalvar(textoBotao) {
                let botaoSalvar = document.getElementById("botao-salvar");
                let conteudoBotao = document.getElementById("conteudo-botao");
                let spinnerLoading = document.getElementById("spinner-loading");

                let novoTextoConteudoBotao = "Carregando"

                //mudar para o carregando
                botaoSalvar.disabled = true;
                botaoSalvar.style.backgroundColor = "gray"
                conteudoBotao.innerHTML = novoTextoConteudoBotao;
                conteudoBotao.style.display = "inline-block"
                spinnerLoading.style.visibility = "visible"
                spinnerLoading.style.position = "relative"

                if (this.erro == true) {
                    //mudar para o padrão (da aba dados básicos)
                    botaoSalvar.disabled = false;
                    botaoSalvar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                }
            },
            async enviarForm() {
                let conteudoBotao = document.getElementById("conteudo-botao");
                const textoConteudoBotao = conteudoBotao.innerHTML;

                let flag = this.validarForm(this.orgaoForm);

                if (flag == 1) {
                    this.erro = true;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false
                }

                try {
                    this.carregando = true;
                    this.erro = false;
                    this.msgErros = '';

                    this.mudarBotaoSalvar(textoConteudoBotao);

                    if (this.orgaoApi.cancelTokens.setOrgao) {
                        this.orgaoApi.cancelTokens.setOrgao.cancel();
                    }

                    if (this.orgaoForm.esfera !== 0 || this.orgaoForm.esfera !== 1 || this.orgaoForm.esfera !== 2 || this.orgaoForm.esfera !== 3) {
                        this.orgaoForm.esfera = this.retornarIdEsfera(this.esferas)
                    }
                    if (this.orgaoForm.poder !== 0 || this.orgaoForm.poder !== 1 || this.orgaoForm.poder !== 2 || this.orgaoForm.poder !== 3) {
                        this.orgaoForm.poder = this.retornarIdPoder(this.poderes)
                    }
                    let data;
                    data = await this.orgaoApi.setOrgao(
                        this.orgaoForm
                    );

                    var rota = this.$router;
                    setTimeout(function () {
                        rota.push({
                            name: "OrgaoShow",
                            params: {
                                id: data.id,
                            },
                        });
                    }, 1000);
                    //window.location.reload();
                } catch (err) {
                    if (this.orgaoApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);
                    this.carregando = false;
                    this.erro = true;

                    this.mudarBotaoSalvar(textoConteudoBotao)

                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros =
                            "Houve um erro ao salvar o registro. Por favor tente novamente.";
                    }
                }
            },
            validarForm(orgaoForm) {
                let flag = 0;

                //Resetar as mensagens de erro
                this.msgErros.nome = ''
                this.msgErros.sigla = ''
                this.msgErros.esfera = ''
                this.msgErros.poder = ''
                this.msgErros.ufs_id = ''
                this.msgErros.default = ''

                //Nome não pode estar em branco
                if (orgaoForm.nome == null || orgaoForm.nome == '') {
                    this.msgErros.nome = "O campo nome não foi preenchido.";
                    flag = 1;
                }

                //Sigla não pode estar em branco
                if (orgaoForm.sigla == null || orgaoForm.sigla == '') {
                    this.msgErros.sigla = "O campo sigla não foi preenchido.";
                    flag = 1;
                }

                //Esfera não pode estar em branco
                if (orgaoForm.esfera == null || orgaoForm.esfera == '') {
                    this.msgErros.esfera = "O campo esfera não foi preenchido.";
                    flag = 1;
                }

                //Uf não pode estar em branco
                if (orgaoForm.ufs_id == null || orgaoForm.ufs_id == '') {
                    this.msgErros.ufs_id = "O campo uf não foi preenchido.";
                    flag = 1;
                }
                
                //Poder não pode estar em branco
                if (orgaoForm.poder == null || orgaoForm.poder == '') {
                    this.msgErros.poder = "O campo poder não foi preenchido.";
                    flag = 1;
                }

                return flag;
            },
            possuiAsterisco(texto) {
                var texto_final = "* " + texto
                return texto_final
            },
            formatarCPF(cpf) {
                // Lógica para formatar o CPF (exemplo: 123.456.789-01)
                return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            },
        },

    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>

    @media screen and (min-width: "1066px") {
        .caixa-form {
            .flexbox-1 {
                display: grid;
                grid-template-columns: 2fr 1fr;
                gap: 2rem;
            }

            .flexbox-2 {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
            }

            .flexbox-3 {
                display: grid;
                grid-template: "a b c";
                grid-template-columns: 1.5fr 1.5fr 1.5fr;
                gap: 1.5rem;
            }
        }
    }
</style>
